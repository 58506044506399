import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { EnvironmentSwitcher } from '@bighealth/react-limbix-ui';

import App from './App';
import theme from './theme';

import { Login, ResetPassword } from '@/pages/Common';
import { client } from '@/apollo';
import store from '@/store';
import history from '@/utils/history';

import './index.css';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2e372ff2575e45879e7bdda5fac6c44b@o58970.ingest.sentry.io/5853220',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_DEPLOY_ENV,
    release: `resops@${process.env.APP_VERSION}`,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <ApolloProvider client={client}>
            <Router history={history}>
              <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/reset-password" exact component={ResetPassword} />
                <Route component={App} />
              </Switch>
            </Router>
            <EnvironmentSwitcher />
          </ApolloProvider>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
