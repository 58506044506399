import React from 'react';
import moment from 'moment';
import { Redirect, useParams } from 'react-router-dom';
import {
  Grid, Paper, Divider, Box, Switch,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';

import { LoadingSpinner } from '@bighealth/react-limbix-ui';

import Styled from './StudySettings.styles';

import { STUDY_QUERY } from '@/apollo/queries';
import { SET_USER_BLINDED } from '@/apollo/mutations';
import {
  QueryLimbixStudyArgs, StudyParticipantType, UserType, MutationSetUserBlindedArgs,
} from '@/apollo/types';
import { UseParamsType } from '@/types';
import { useModal, usePermissions } from '@/hooks/redux';
import { useQuery, useMutation } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const StudySettings: React.FC = () => {
  const { studyUid } = useParams<UseParamsType>();
  const { showModal } = useModal();
  const [{ studyPermissions }] = usePermissions();
  const { loading, data, error } = useQuery<QueryLimbixStudyArgs>(STUDY_QUERY, {
    variables: {
      studyUid,
    },
  });

  const refetchQueries = [
    {
      query: STUDY_QUERY,
      variables: {
        studyUid,
      },
    },
  ];

  const [setUserBlinded] = useMutation<MutationSetUserBlindedArgs>(
    SET_USER_BLINDED, { refetchQueries, errorPolicy: 'all' },
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error) || !data?.limbixStudy) {
    return <Redirect to="/research" />;
  }

  const currStudyPermissons = studyPermissions[studyUid];
  const { canChangeStudy } = currStudyPermissons;

  const { limbixStudy, limbixStudy: { studyParticipants } } = data;

  const filterOutParents = (_studyParticipants: StudyParticipantType[]) => (
    _studyParticipants.filter((participant) => (
      participant.user.accountType !== 'PARENT'
    ))
  );

  const getStatusCounts = (_studyParticipants: StudyParticipantType[]) => (
    _studyParticipants.map((participant) => (
      participant.status
    )).reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: 1 + (prev[curr] || 0),
      }),
      ({} as Record<string, number>),
    )
  );

  const studyStartTime = limbixStudy?.startTime ? moment(limbixStudy.startTime).format('lll') : 'None';
  const studyEndTime = limbixStudy?.endTime ? moment(limbixStudy.endTime).format('lll') : 'None';
  const daysSinceStarted = limbixStudy?.startTime ? moment(new Date()).diff(limbixStudy.startTime, 'days') : '0';
  const totalParticipants = filterOutParents(studyParticipants).length;
  const statusCounts = getStatusCounts(filterOutParents(studyParticipants));

  const displayStudyStaff = (user: UserType, role: string) => {
    const action = user.isBlindedForStudy ? 'Unblind' : 'Blind';
    const handleToggleBlindness = () => showModal(
      'CONFIRMATION_POPUP',
      {
        header: 'Update Blinding Status',
        body: ` ${action} user ${user.email}?`,
        mutation: () => setUserBlinded({
          variables: {
            studyUid,
            userEmail: user.email,
            blinded: !user.isBlindedForStudy,
          },
        }),
        context: {
          Role: role,
          Email: user.email,
          'New Blinding Status': user.isBlindedForStudy ? 'unblinded' : 'blinded',
        },
      },
    );

    return (
      <Box key={user.email} marginBottom="10px">
        <Styled.Text marginbottom="0px">
          {user.email}
        </Styled.Text>
        <Switch
          color="primary"
          checked={user.isBlindedForStudy}
          value={user.isBlindedForStudy}
          onChange={handleToggleBlindness}
        />
        {user.isBlindedForStudy ? 'blinded' : 'unblinded'}
      </Box>

    );
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Styled.Container>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <Paper style={{ padding: '30px', marginBottom: '20px', display: 'flex' }} elevation={3}>
              <Styled.StudyInfoContainer>
                <Styled.TextGroupContainer width="50%">
                  <Styled.BoldText>
                    Study Name:
                  </Styled.BoldText>
                  <div style={{ display: 'flex' }}>
                    <Styled.Text marginbottom="24px">
                      {limbixStudy.name}
                    </Styled.Text>
                    {canChangeStudy && (
                      <div style={{ display: 'flex', marginLeft: '10px', cursor: 'pointer' }}>
                        <EditIcon onClick={() => showModal('CHANGE_STUDY_NAME', {
                          limbixStudy,
                        })}
                        />
                      </div>
                    )}
                  </div>
                  <Styled.BoldText>
                    Start Time:
                  </Styled.BoldText>
                  <Styled.Text marginbottom="24px">
                    {studyStartTime}
                  </Styled.Text>
                  <Styled.BoldText>
                    Days Since Study Started:
                  </Styled.BoldText>
                  <Styled.Text marginbottom="0px">
                    {`${daysSinceStarted} days`}
                  </Styled.Text>
                </Styled.TextGroupContainer>
                <Styled.TextGroupContainer width="40%">
                  <Styled.BoldText>
                    Status:
                  </Styled.BoldText>
                  <Styled.Text marginbottom="24px">
                    {limbixStudy.status}
                  </Styled.Text>
                  <Styled.BoldText>
                    End Time:
                  </Styled.BoldText>
                  <Styled.Text marginbottom="0px">
                    {studyEndTime}
                  </Styled.Text>
                </Styled.TextGroupContainer>
              </Styled.StudyInfoContainer>
              <Divider orientation="vertical" flexItem />
              <Styled.ParticipantInfoContainer>
                <div style={{ width: '100%' }}>
                  <Styled.BoldText>
                    Total # of Participants (Not Parents):
                  </Styled.BoldText>
                  <Styled.Text marginbottom="24px" marginleft="20px">
                    {totalParticipants}
                  </Styled.Text>
                </div>
                <div style={{ width: '100%', display: 'flex' }}>
                  <Styled.TextGroupContainer width="30%">
                    <Styled.BoldText>
                      # Enrolled:
                    </Styled.BoldText>
                    <Styled.Text marginbottom="24px" marginleft="20px">
                      {statusCounts?.enrolled || 0}
                    </Styled.Text>
                    <Styled.BoldText>
                      # Withdrawn:
                    </Styled.BoldText>
                    <Styled.Text marginbottom="0px" marginleft="20px">
                      {statusCounts?.withdrawn || 0}
                    </Styled.Text>
                  </Styled.TextGroupContainer>
                  <Styled.TextGroupContainer width="35%">
                    <Styled.BoldText>
                      # Activated:
                    </Styled.BoldText>
                    <Styled.Text marginbottom="24px" marginleft="20px">
                      {statusCounts?.active || 0}
                    </Styled.Text>
                    <Styled.BoldText>
                      # Lost to Follow Up:
                    </Styled.BoldText>
                    <Styled.Text marginbottom="0px" marginleft="20px">
                      {statusCounts['lost to follow-up'] || 0}
                    </Styled.Text>
                  </Styled.TextGroupContainer>
                  <Styled.TextGroupContainer width="30%">
                    <Styled.BoldText>
                      # Completed:
                    </Styled.BoldText>
                    <Styled.Text marginbottom="24px" marginleft="20px">
                      {statusCounts?.complete || 0}
                    </Styled.Text>
                    <Styled.BoldText>
                      # Dropped:
                    </Styled.BoldText>
                    <Styled.Text marginbottom="0px" marginleft="20px">
                      {statusCounts?.dropped || 0}
                    </Styled.Text>
                  </Styled.TextGroupContainer>
                </div>
              </Styled.ParticipantInfoContainer>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ padding: '30px', marginBottom: '20px', display: 'flex' }} elevation={3}>
              <Styled.StudyPersonnelContainer>
                <Styled.BoldText marginbottom="20px">
                  Study Admin
                </Styled.BoldText>
                {limbixStudy.principalInvestigators.map((user) => (
                  <>
                    {displayStudyStaff(user, 'Study Admin')}
                  </>
                ))}
              </Styled.StudyPersonnelContainer>
              <Divider orientation="vertical" flexItem />
              <Styled.StudyPersonnelContainer marginleft="30px">
                <div style={{ display: 'flex' }}>
                  <Styled.BoldText marginbottom="20px">
                    Study Operator
                  </Styled.BoldText>
                  {canChangeStudy && (
                    <div style={{ display: 'flex', marginLeft: '10px', cursor: 'pointer' }}>
                      <PersonAddRoundedIcon
                        fontSize="large"
                        onClick={() => showModal('ADD_STUDY_PERSONNEL', {
                          limbixStudy,
                          type: 'Study CoInvestigator',
                        })}
                      />
                    </div>
                  )}
                </div>
                {limbixStudy.coInvestigators.map((user) => (
                  <div key={user.email} style={{ display: 'flex' }}>
                    <>
                      {displayStudyStaff(user, 'Study Operator')}
                    </>
                    {canChangeStudy && (
                      <div style={{ display: 'flex', marginLeft: '10px', cursor: 'pointer' }}>
                        <DeleteIcon onClick={() => showModal('REMOVE_STUDY_PERSONNEL', {
                          limbixStudy,
                          user,
                          type: 'Study CoInvestigator',
                        })}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </Styled.StudyPersonnelContainer>
              <Divider orientation="vertical" flexItem />
              <Styled.StudyPersonnelContainer marginleft="30px">
                <div style={{ display: 'flex' }}>
                  <Styled.BoldText marginbottom="20px">
                    Read-Only
                  </Styled.BoldText>
                  {canChangeStudy && (
                    <div style={{ display: 'flex', marginLeft: '10px', cursor: 'pointer' }}>
                      <PersonAddRoundedIcon
                        fontSize="large"
                        onClick={() => showModal('ADD_STUDY_PERSONNEL', {
                          limbixStudy,
                          type: 'Study Coordinator',
                        })}
                      />
                    </div>
                  )}
                </div>
                {limbixStudy.studyCoordinators.map((user) => (
                  <div key={user.email} style={{ display: 'flex' }}>
                    <Box marginBottom="15px">
                      {displayStudyStaff(user, 'Read-Only')}
                    </Box>
                    {canChangeStudy && (
                      <div style={{ display: 'flex', marginLeft: '10px', cursor: 'pointer' }}>
                        <DeleteIcon onClick={() => showModal('REMOVE_STUDY_PERSONNEL', {
                          limbixStudy,
                          user,
                          type: 'Study Coordinator',
                        })}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </Styled.StudyPersonnelContainer>
            </Paper>
          </Grid>
        </Grid>
      </Styled.Container>
    </div>
  );
};

export default StudySettings;
