import React, { useEffect } from 'react';
import { Box } from '@bighealth/react-limbix-ui';

import {
  AssessmentInputType,
  MutationCreateAssessmentArgs,
} from '@/apollo/types';
import { AssessmentEditor } from '@/components';
import history from '@/utils/history';
import { CREATE_ASSESSMENT } from '@/apollo/mutations';
import { useModal, usePreventNavigation } from '@/hooks/redux';
import { useMutation } from '@/hooks/apollo';

const CreateAssessment: React.FC = () => {
  const { showModal } = useModal();
  const [, { setPreventNavigation, resetListenerEvent }] = usePreventNavigation();

  useEffect(() => {
    setPreventNavigation({ isBlocked: true, listeners: ['keyup', 'click'] });
  }, []);

  const [createAssessmentMutation, mutationResult] = useMutation<MutationCreateAssessmentArgs>(
    CREATE_ASSESSMENT,
  );
  const createAssessment = async (assessmentData: AssessmentInputType) => (
    createAssessmentMutation({
      variables: {
        assessmentData,
      },
    })
  );

  const handleSave = async (assessmentData: AssessmentInputType) => {
    const result = await createAssessment(assessmentData);
    if (result.errors) {
      showModal(
        'ERROR_POPUP',
        {
          header: 'Error While Creating Assessment',
          errors: result.errors.map((mutationError) => mutationError.message),
        },
      );
    } else {
      resetListenerEvent();
      const assessment = result?.data?.createAssessment?.assessment;
      history.push(`/operations/assessment/${assessment?.uid}/edit`);
    }
  };

  return (
    <Box>
      <AssessmentEditor onSave={handleSave} saving={mutationResult?.loading} />
    </Box>
  );
};

export default CreateAssessment;
