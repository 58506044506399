import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from '@bighealth/react-limbix-ui';

import Styled from '../Modal.styles';

import { SearchBar } from '@/components';
import { PatientType } from '@/apollo/types';
import { formatPractitionerName } from '@/utils/stringUtils';
import { hasSearchString } from '@/utils/tableUtils';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['VIEW_PATIENTS'];
  onClose: () => void;
};

const ViewPatients: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const [selectedPatient, setSelectedPatient] = useState({
    clicked: false,
    searchFilter: null as string,
  });
  const [searchString, setSearchString] = useState('');

  const close = async () => (
    onClose()
  );

  const {
    practitioner,
    pdtUid,
  } = payload;

  if (selectedPatient.clicked) {
    const basePDTUrl = `/operations/pdt/${pdtUid}`;
    const linkToUID = `${basePDTUrl}/patient`;
    close();
    return (
      <Redirect to={{ pathname: linkToUID, state: selectedPatient }} />
    );
  }

  const renderPatient = (patient: PatientType) => (
    <li key={patient.uid}>
      <Link.ButtonLink
        onClick={() => setSelectedPatient({ clicked: true, searchFilter: patient.uid })}
        fontSize="16px"
      >
        {`${patient.user.lastName}, ${patient.user.firstName}` || 'None'}
      </Link.ButtonLink>
      <ul style={{ paddingLeft: '15px' }}>
        <li>
          Email:
          {` ${patient.user.email}` || 'None'}
        </li>
      </ul>
    </li>
  );

  const filteredPatients = practitioner?.patients.filter((patient) => {
    if (!searchString || searchString === '') {
      return true;
    }
    const name = `${patient.user.lastName}, ${patient.user.firstName}`?.toLowerCase();
    const email = patient.user.email?.toLowerCase();
    try {
      return hasSearchString([name, email], searchString);
    } catch (error) {
      return true;
    }
  }) || [];

  return (
    <div style={{ width: '450px', height: '500px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.TextContainer>
            <Styled.HeaderText>
              View Patients for
              {` ${formatPractitionerName(practitioner)}`}
            </Styled.HeaderText>
            <div
              style={{
                width: '75%',
                maxHeight: '100%',
                marginBottom: '10px',
              }}
            >
              <SearchBar
                value={searchString}
                placeholder="Filter Patients"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => (
                  setSearchString(event.currentTarget.value?.toLowerCase() || '')
                )}
              />
            </div>
            <div style={{ height: '325px', overflowY: 'scroll' }}>
              Scroll to see more...
              <ul style={{ paddingLeft: '15px' }}>
                {filteredPatients.map((patient) => renderPatient(patient))}
              </ul>
            </div>
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              Close
            </Styled.NoButtonText>
          </Styled.NoButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default ViewPatients;
