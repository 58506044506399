import React from 'react';
import { Link, Box } from '@bighealth/react-limbix-ui';

import { StudyParticipantType } from '@/apollo/types';
import { StudyPermission } from '@/types';
import { useModal } from '@/hooks/redux';
import { UnknownAsyncCallback } from '@/types/DataTypes';

type Props = {
  participant: StudyParticipantType;
  studyPermissions: StudyPermission;
  onRefetch?: UnknownAsyncCallback;
};
const MobileAccess: React.FC<Props> = (props: Props) => {
  const { participant, studyPermissions, onRefetch } = props;
  const { showModal } = useModal();

  const showRevokeAccessModal = () => showModal(
    'REVOKE_ACCESS',
    {
      studyParticipant: participant,
      onRefetch,
    },
  );

  const renderMobileAccess = () => {
    let text = 'No Mobile Access';

    if (participant.canAccessPdt) {
      text = 'Revoke';
      if (studyPermissions.canModifyParticipants) {
        return (
          <Box>
            <Link.ButtonLink onClick={showRevokeAccessModal}>
              {text}
            </Link.ButtonLink>
          </Box>
        );
      }
    }

    if (!participant.canAccessPdt && participant.hasMobileTokens) {
      text = 'Revoke (Should not have access to PDT)';
      if (studyPermissions.canModifyParticipants) {
        return (
          <Box>
            <Link.ButtonLink onClick={showRevokeAccessModal}>
              {text}
            </Link.ButtonLink>
          </Box>
        );
      }
    }
    return text;
  };

  return (
    <Box>
      {renderMobileAccess()}
    </Box>
  );
};

export default MobileAccess;
