import React from 'react';
import { Select } from '@material-ui/core';
import { Memo, Text, Box } from '@bighealth/react-limbix-ui';

import { AssignmentStatus, AccountType, UnknownChangeEvent } from '@/types';
import {
  AssignmentScheduleAccountType,
  AssignmentScheduleScheduleOnStudyState,
  AssignmentTypeOfClient,
  AssignmentScheduleTypeOfNotificationForAssignment,
  LanguageType,
} from '@/apollo/types';

type EnumUnionType = typeof AssignmentStatus
  | typeof AccountType
  | typeof AssignmentScheduleAccountType
  | typeof AssignmentScheduleScheduleOnStudyState
  | typeof AssignmentTypeOfClient
  | typeof AssignmentScheduleTypeOfNotificationForAssignment
  | typeof LanguageType;

type EnumSelectProps = {
  value: string,
  enumType: EnumUnionType,
  enumValToLabelMap?: Record<string, string>
  enumValuesToExclude?: string[];
  label: string,
  allowNone?: boolean,
  inline?: boolean,
  disabled?: boolean,
  onSelect: (event: UnknownChangeEvent) => void,
}
const EnumSelect: React.FC<EnumSelectProps> = (props: EnumSelectProps) => {
  const {
    value,
    enumType,
    enumValToLabelMap,
    enumValuesToExclude,
    label,
    allowNone,
    disabled,
    inline,
    onSelect,
  } = props;

  return (
    <Box display="block">
      <Box display="inline-flex" margin="0px 12px" alignItems="center">
        {
          !inline && (
            <Text marginRight="10px" fontSize="18px">
              {label}
            </Text>
          )
        }
        <Box display="block">
          <Select
            labelId="select-assignment-status"
            id="select-assignment-status"
            native
            disabled={disabled}
            value={value}
            onChange={onSelect}
            inputProps={{
              id: 'select-multiple-native',
            }}
          >
            {Object.entries(enumType).filter(([, val]) => (
              (!(enumValuesToExclude && enumValuesToExclude.includes(val)) && (val !== '' || allowNone))
            )).map(([key, val]) => (
              <option key={key} value={val}>
                {enumValToLabelMap ? enumValToLabelMap[val] : key.split('_').join(' ')}
              </option>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default Memo(EnumSelect, (prevProps, nextProps) => (
  prevProps.value === nextProps.value
));
