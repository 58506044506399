import React from 'react';
import {
  PaginatedTable,
  Column,
  Row,
  Cell,
  createCell,
  createColumn,
} from '@bighealth/react-limbix-ui';

import { AssessmentResultItemType, AssessmentType } from '@/apollo/types';
import { assessmentResultItemToAnswerString } from '@/utils/assessmentUtils';

type TablePropsWithOmissions = Omit<
  React.ComponentProps<typeof PaginatedTable>,
  'rows' | 'columns'
>
type BaseAssessmentResultType = {
  assessmentResultItems?: Array<AssessmentResultItemType>;
  uid: string;
}
type Props<ResultType extends BaseAssessmentResultType> = TablePropsWithOmissions & {
  assessment: AssessmentType;
  assessmentResults: Array<ResultType>;
  staticColumns: Column[];
  staticRows: Row[];
};

const PaginatedAssessmentTable = <ResultType extends BaseAssessmentResultType>(props: Props<ResultType>) => {
  const {
    assessment,
    assessmentResults,
    staticColumns,
    staticRows,
    ...tableProps
  } = props;

  const { questions } = assessment;

  const questionColumns: Column[] = questions.map((question) => createColumn(
    question.ordinal.toString(), question.text, 'string', null, false,
  ));

  const columns = [...staticColumns, ...questionColumns];

  const questionRows: Cell[][] = (assessmentResults || []).map((result) => {
    const { assessmentResultItems } = result;
    const ordinalToResultItemMap: Record<string, AssessmentResultItemType> = {};
    // make map of question ordinal -> assessment result item
    assessmentResultItems.forEach((item) => {
      ordinalToResultItemMap[item.question.ordinal.toString()] = item;
    });
    return questionColumns.map((column) => (createCell(
      column.id,
      assessmentResultItemToAnswerString(ordinalToResultItemMap[column.id]),
    )));
  });

  const rows: Row[] = staticRows.map((staticRow, index) => ({
    id: staticRow.id,
    cells: [...staticRow.cells, ...questionRows[index]],
  }));

  return (
    <PaginatedTable
      {...tableProps}
      columns={columns}
      rows={rows}
    />
  );
};

export default PaginatedAssessmentTable;
