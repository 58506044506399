import React from 'react';
import styled from 'styled-components';
import { Memo } from '@bighealth/react-limbix-ui';

import { AssignmentPathInfo } from '@/types';

const G = styled.g`
  &:hover {
    cursor: pointer;
  }
`;

type AssignmentSchedulePathProps = {
  assignmentPathInfo: AssignmentPathInfo
}
export const AssignmentSchedulePath: React.FC<AssignmentSchedulePathProps> = Memo(({
  assignmentPathInfo,
}) => (
  <G
    onMouseOver={assignmentPathInfo.onMouseOver}
    onMouseLeave={assignmentPathInfo.onMouseLeave}
  >
    <title>
      {assignmentPathInfo.title}
    </title>
    {assignmentPathInfo.rect.map((rect) => (
      <rect
        key={rect.key}
        x={rect.x}
        y={rect.y}
        width={rect.width}
        height={rect.height}
        fill={rect.fill}
        fillOpacity={rect.fillOpacity}
      />
    ))}
  </G>
));

type WeekGridRowProps = {
  weekNumber: number,
  cellSize: number,
}
export const WeekGridRow: React.FC<WeekGridRowProps> = Memo(({
  weekNumber,
  cellSize,
}) => (
  <>
    {
      Array(7).fill(null).map((_, index) => {
        if (index === 0) {
          return (
            <g key={+index}>
              <rect
                x={cellSize * index}
                y={cellSize * weekNumber}
                width={cellSize}
                height={cellSize}
                fillOpacity={0}
                stroke="#444444"
                strokeWidth={1}
              />
              <text
                x={cellSize * index + 5}
                y={cellSize * weekNumber + 15}
              >
                {`Week: ${weekNumber + 1}`}
              </text>
            </g>
          );
        }
        return (
          <g key={+index}>
            <rect
              x={cellSize * index}
              y={cellSize * weekNumber}
              width={cellSize}
              height={cellSize}
              fillOpacity={0}
              stroke="#444444"
              strokeWidth={1}
            />
          </g>
        );
      })
    }
  </>
));
