import React from 'react';
import { Link, Box } from '@bighealth/react-limbix-ui';

import {
  LanguageType,
  LimbixUserAccountType,
  MutationResendEmailVerificationArgs,
  MutationResetPasswordArgs,
  StudyParticipantType,
} from '@/apollo/types';
import { RESEND_VERIFICATION_EMAIL, RESET_PASSWORD } from '@/apollo/mutations';
import { useModal } from '@/hooks/redux';
import { useMutation } from '@/hooks/apollo';
import LanguageSetter from '@/components/LanguageSetter';
import { UnknownAsyncCallback } from '@/types/DataTypes';

type Props = {
  participant: StudyParticipantType;
  onRefetch?: UnknownAsyncCallback;
};
const AdminActions: React.FC<Props> = (props: Props) => {
  const { participant, onRefetch } = props;
  const { showModal } = useModal();

  const [resendEmailVerificationMutation] = useMutation<MutationResendEmailVerificationArgs>(
    RESEND_VERIFICATION_EMAIL,
  );
  const resendEmailVerification = async (email: string) => (
    resendEmailVerificationMutation({
      variables: {
        email,
      },
    })
  );

  const [sendPasswordResetMutation] = useMutation<MutationResetPasswordArgs>(
    RESET_PASSWORD,
  );
  const sendPasswordReset = async (email: string) => (
    sendPasswordResetMutation({
      variables: {
        email,
      },
    })
  );

  const isChild = participant.user.accountType === LimbixUserAccountType.Child;
  const participantEmailVerified = participant.user.emailVerified;
  return (
    <Box>
      <Link.ButtonLink
        onClick={
          () => showModal(
            'CONTACT_INFO',
            { studyParticipant: participant },
          )
        }
      >
        view contact info
      </Link.ButtonLink>
      {!participantEmailVerified && (
        <>
          {' | '}
          <Link.ButtonLink
            onClick={
              () => showModal(
                'CONFIRMATION_POPUP',
                {
                  header: 'Would you like to resend account verification?',
                  mutation: () => resendEmailVerification(participant.user.email),
                  context: { Recipient: participant.user.email },
                },
              )
            }
          >
            send account verification
          </Link.ButtonLink>
        </>
      )}
      {' | '}
      <Link.ButtonLink
        onClick={
          () => showModal(
            'CONFIRMATION_POPUP',
            {
              header: 'Would you like to reset the participant\'s password?',
              mutation: () => sendPasswordReset(participant.user.email),
              context: { Recipient: participant.user.email },
            },
          )
        }
      >
        send password reset
      </Link.ButtonLink>
      {' | '}
      <LanguageSetter
        participantEmail={participant.user.email}
        participantId={participant.participantId}
        participantLanguage={participant.user.language as unknown as LanguageType}
        participantUserUid={participant.user.uid}
        studyUid={participant.study.uid}
        candidateLanguages={participant.study.languages}
        onRefetch={onRefetch}
      />
      {isChild && (
        <>
          {' | '}
          <Link.ButtonLink
            onClick={
              () => showModal(
                'EDIT_PARENTS',
                {
                  studyParticipant: participant,
                  onRefetch,
                },
              )
            }
          >
            edit parents
          </Link.ButtonLink>
        </>
      )}
    </Box>
  );
};

export default AdminActions;
