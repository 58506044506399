import React, { useState } from 'react';
import { Divider } from '@material-ui/core';
import { Box, Text } from '@bighealth/react-limbix-ui';

import Styled from './Details.styles';

import { formatDate } from '@/utils/dateUtils';
import { AssessmentResultItemType, QuestionQuestionType, QuestionType } from '@/apollo/types';
import { WithExpandedField } from '@/types';
import { REDACTED_FOR_BLINDING_MESSAGE } from '@/utils/constants';

type Props = {
  questions: QuestionType[];
  assessmentAnswers: AssessmentResultItemType[];
  score?: number;
  completeTime: Date;
  isBlinded?: boolean;
}

type ResultItemWithExpansion = WithExpandedField<Partial<AssessmentResultItemType>>;

const AssessmentResultDisplay: React.FC<Props> = (props: Props) => {
  const {
    assessmentAnswers, score, completeTime, questions, isBlinded,
  } = props;
  const [assessmentResultQA, setAssessmentResultQA] = useState<ResultItemWithExpansion[]>([]);

  const answerData = questions.map((question) => {
    const matchingItem = assessmentAnswers.find((item) => item.question.uid === question.uid);
    // If question not answered, add "empty" assessment result item
    const resultItem: Partial<AssessmentResultItemType> = matchingItem
      ? { ...matchingItem }
      : {
        assessmentValue: null,
        value: '-',
        question: { ...question },
      };
    return ({ ...resultItem, expanded: false });
  });

  if (assessmentResultQA.length === 0) {
    setAssessmentResultQA(answerData);
  }

  let displayScore = false;
  if (assessmentResultQA.length > 0) {
    displayScore = !assessmentResultQA.find((qa) => qa.assessmentValue === null);
  }

  const handleMouseOver = (index: number) => {
    setAssessmentResultQA((curr) => {
      const qaList = [...curr];
      qaList[index].expanded = true;
      return qaList;
    });
  };

  const handleMouseExit = (index: number) => {
    setAssessmentResultQA((curr) => {
      const qaList = [...curr];
      qaList[index].expanded = false;
      return qaList;
    });
  };

  return (
    <Box>
      <Text fontWeight="700" fontSize="22px">
        Assessment Result
      </Text>
      <Divider />
      <Text fontWeight="700" fontSize="18px">
        {formatDate(completeTime)}
      </Text>
      <small>
        (hover over question text to expand)
      </small>
      <Box style={{ height: '75%', marginBottom: '15px' }}>
        <Box style={{ marginLeft: '20px' }}>
          <Box width="100%" margin="10px 0 5px 0" display="flex">
            <Box display="flex" width="55%" marginRight="20px">
              <Text fontWeight="700" fontSize="22px">
                Question
              </Text>
            </Box>
            <Box display="flex" width="calc(30% - 20px)">
              <Text fontWeight="700" fontSize="22px">
                Answer Text
              </Text>
            </Box>
            <Box display="flex" width="calc(10% - 20px)">
              <Text fontWeight="700" fontSize="22px">
                Score
              </Text>
            </Box>
            <br />
          </Box>
        </Box>
        <ol>
          {
            assessmentResultQA.map((qa, index) => (
              <li key={qa.question.ordinal}>
                <Box width="100%" margin="10px 0 5px 0" display="flex" borderBottom="1px solid #000000">
                  <Box
                    onMouseEnter={() => handleMouseOver(index)}
                    onMouseLeave={() => handleMouseExit(index)}
                    width="55%"
                    marginRight="20px"
                  >
                    {qa.expanded ? (
                      <Styled.QuestionTextExpanded>
                        {qa.question.text}
                      </Styled.QuestionTextExpanded>
                    ) : (
                      <Styled.QuestionTextCollapsed>
                        {qa.question.text}
                      </Styled.QuestionTextCollapsed>
                    )}
                  </Box>
                  <Box display="flex" width="calc(30% - 20px)">
                    {
                      isBlinded && qa.question.questionType === QuestionQuestionType.FreeFormText && qa.value !== ''
                        ? REDACTED_FOR_BLINDING_MESSAGE
                        : (
                          <Text fontSize="16px">
                            {qa.value}
                          </Text>
                        )
                    }
                  </Box>
                  <Box display="flex" width="calc(10% - 40px)" minWidth="50px" justifyContent="center">
                    <Text fontSize="16px">
                      {qa.assessmentValue === null ? '-' : qa.assessmentValue}
                    </Text>
                  </Box>
                  <br />
                </Box>
              </li>
            ))
          }
        </ol>
      </Box>
      <Box position="absolute" bottom="0" width="100%" display="flex" justifyContent="flex-end" paddingRight="15%">
        <Text margin="0 10px 0 auto" fontSize="18px" fontWeight="700">
          {`Total: ${displayScore ? score : '-'}`}
        </Text>
      </Box>
    </Box>
  );
};

export default AssessmentResultDisplay;
