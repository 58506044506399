import React from 'react';
import {
  TextField,
  Select,
  Switch,
  Button,
  FormControlLabel,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import WarningIcon from '@material-ui/icons/WarningOutlined';

import {
  Link,
  Memo,
  LoadingSpinner,
  Box,
  Text,
} from '@bighealth/react-limbix-ui';

import {
  ConditionType,
  AssessmentQuestionType,
  AssessmentAnswerType,
  AssessmentField,
  QuestionField,
  ActionTypeEnum,
} from './AssessmentEditor.types';

import { IconButton } from '@/components';
import { QuestionQuestionType } from '@/apollo/types';
import { UnknownChangeEvent, HTMLInputChangeEvent } from '@/types';

type ErrorMessageProps = {
  error: string,
}
const ErrorMessage: React.FC<ErrorMessageProps> = Memo(({
  error,
}) => {
  if (error) {
    return (
      <Box margin="10px 0px" display="flex" flexDirection="row">
        <WarningIcon color="error" fontSize="small" />
        <Text color="red" margin="0px 6px">
          {error}
        </Text>
      </Box>
    );
  }
  return null;
});

type AssessmentHeaderProps = {
  onUploadFile: (file: File) => Promise<void>;
  stateToUriJson: () => string;
  assessmentInternalName: string;
  creating: boolean;
  isLocked?: boolean;
};
const AssessmentHeader: React.FC<AssessmentHeaderProps> = Memo(({
  onUploadFile,
  stateToUriJson,
  creating,
  isLocked,
  assessmentInternalName,
}) => (
  <Box display="flex">
    <Box display="block" width="40%">
      <Text as="h1">
        Assessment
        {`${creating ? ' Creator' : ' Editor'}`}
      </Text>
      {isLocked && (
        <Text as="h4" color="red">
          *Assessment is locked and changes cannot be made to questions, answers, or conditions
        </Text>
      )}
    </Box>
    <Box margin="auto 0 auto auto">
      <Button
        style={{ margin: '0px 10px' }}
        variant="contained"
        color="default"
        component="label"
      >
        Import Assessment
        <input
          type="file"
          hidden
          onChange={(event) => onUploadFile(event.target.files[0])}
        />
      </Button>
      <Link.DownloadLink
        href={stateToUriJson()}
        download={`${assessmentInternalName || 'assessment'}.json`}
      >
        <Button
          variant="contained"
          color="primary"
        >
          Export Assessment
        </Button>
      </Link.DownloadLink>
    </Box>
  </Box>
));

type AssessmentFieldTextInputProps = {
  field: AssessmentField;
  label: string;
  fieldValue: string;
  onEditAssessmentField: (event: HTMLInputChangeEvent, field: AssessmentField) => void;
  helperText?: string;
};
const AssessmentFieldTextInput: React.FC<AssessmentFieldTextInputProps> = Memo(({
  field,
  label,
  fieldValue,
  onEditAssessmentField,
  helperText,
}) => (
  <>
    <Text as="h2">
      {label}
      :
    </Text>
    {
      helperText && (
        <Text margin="8px 0px">
          {helperText}
        </Text>
      )
    }
    <Box>
      <TextField
        id={field}
        variant="outlined"
        label={label}
        multiline
        maxRows={8}
        value={fieldValue}
        style={{ width: '350px' }}
        size="small"
        onChange={(event) => onEditAssessmentField(event, field)}
      />
    </Box>
  </>
));

type IsArchivedSwitchProps = {
  value: boolean;
  label: string;
  onFlipSwitch: (checked: boolean) => void;
};
const IsArchivedSwitch: React.FC<IsArchivedSwitchProps> = Memo(({
  value,
  label,
  onFlipSwitch,
}) => (
  <Box>
    <FormControlLabel
      value="start"
      control={(
        <Switch
          color="primary"
          checked={value}
          value={value}
          onChange={(_, checked) => onFlipSwitch(checked)}
        />
      )}
      label={label}
      labelPlacement="start"
    />
  </Box>
));

type QuestionHeaderProps = {
  questionIndex: number;
  onClickMoveUp: () => void;
  onClickMoveDown: () => void;
  onClickRemove: () => void;
};
const QuestionHeader: React.FC<QuestionHeaderProps> = Memo(({
  questionIndex,
  onClickMoveUp,
  onClickMoveDown,
  onClickRemove,
}) => (
  <Box display="flex" margin="20px 0px">
    <IconButton onClick={onClickMoveUp}>
      <ArrowUpwardIcon />
    </IconButton>
    <Text as="h2">
      {`Question: ${questionIndex + 1}`}
    </Text>
    <IconButton onClick={onClickMoveDown}>
      <ArrowDownwardIcon />
    </IconButton>
    <Box style={{ margin: '0 0 0 auto' }}>
      <IconButton onClick={onClickRemove}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Box>
  </Box>
));

type SetQuestionTypeProps = {
  question: AssessmentQuestionType;
  questionIndex: number;
  onSelect: (event: UnknownChangeEvent, questionIndex: number) => void;
};
const SetQuestionType: React.FC<SetQuestionTypeProps> = Memo(({
  question,
  questionIndex,
  onSelect,
}) => (
  <Box margin="20px 0px">
    <Text fontWeight="700">Question Type:</Text>
    <Select
      labelId="select-question-type"
      id="select-question-type"
      native
      value={question.questionType || ''}
      onChange={(event: UnknownChangeEvent) => onSelect(event, questionIndex)}
      inputProps={{
        id: 'select-multiple-native',
      }}
    >
      <option value="">
        Select Type
      </option>
      {Object.entries(QuestionQuestionType).map(([key, value]) => (
        <option key={key} value={value}>
          {key}
        </option>
      ))}
    </Select>
  </Box>
));

type EditQuestionTextFieldProps = {
  questionIndex: number;
  label: string;
  id: string;
  onChange: (event: HTMLInputChangeEvent) => void;
  value: string;
  helperText?: string;
};
const EditQuestionTextField: React.FC<EditQuestionTextFieldProps> = Memo(({
  onChange,
  label,
  value,
  id,
  helperText,
}) => (
  <Box>
    {
      helperText && (
        <Text margin="8px 0px">
          {helperText}
        </Text>
      )
    }
    <Box>
      <TextField
        id={id}
        variant="outlined"
        label={label}
        multiline
        maxRows={8}
        style={{ width: '350px' }}
        value={value || ''}
        size="small"
        onChange={onChange}
      />
    </Box>
  </Box>
));

type EditQuestionPropertyProps = {
  value: boolean;
  field: QuestionField;
  label: string;
  questionIndex: number;
  onFlipSwitch: (checked: boolean, questionIndex: number, field: QuestionField) => void;
};
const EditQuestionProperty: React.FC<EditQuestionPropertyProps> = Memo(({
  value,
  field,
  label,
  questionIndex,
  onFlipSwitch,
}) => (
  <Box>
    <FormControlLabel
      value="start"
      control={(
        <Switch
          color="primary"
          checked={value}
          value={value}
          onChange={(_, checked) => onFlipSwitch(checked, questionIndex, field)}
        />
      )}
      label={label}
      labelPlacement="start"
    />
  </Box>
));

type AnswerHeaderProps = {
  questionIndex: number;
  onClick: (questionIndex: number, type: ActionTypeEnum.ADD_ANSWER | ActionTypeEnum.REMOVE_ANSWER) => void;
};
const AnswerHeader: React.FC<AnswerHeaderProps> = Memo(({
  questionIndex,
  onClick,
}) => (
  <Box display="flex" margin="15px 0px">
    <IconButton onClick={() => onClick(questionIndex, ActionTypeEnum.ADD_ANSWER)}>
      <AddCircleOutlineIcon />
    </IconButton>
    <IconButton onClick={() => onClick(questionIndex, ActionTypeEnum.REMOVE_ANSWER)}>
      <RemoveCircleOutlineIcon />
    </IconButton>
    <Text as="h3">
      Answers:
    </Text>
  </Box>
));

type AnswerProps = {
  questionIndex: number;
  answer: AssessmentAnswerType;
  answerIndex: number;
  onChangeAnswerText: (event: HTMLInputChangeEvent, questionIndex: number, answerIndex: number) => void;
  onChangeAnswerAssessmentValue: (event: HTMLInputChangeEvent, questionIndex: number, answerIndex: number) => void;
};
const Answer: React.FC<AnswerProps> = Memo(({
  questionIndex,
  answer,
  answerIndex,
  onChangeAnswerText,
  onChangeAnswerAssessmentValue,
}) => (
  <Box margin="10px 0px">
    <TextField
      variant="outlined"
      id={`Answer Option ${answerIndex + 1}`}
      label={`Answer Option ${answerIndex + 1}`}
      multiline
      maxRows={8}
      style={{ width: '300px' }}
      value={answer?.text || ''}
      size="small"
      onChange={(event) => onChangeAnswerText(event, questionIndex, answerIndex)}
    />
    <Box marginLeft="10px" display="inline-flex">
      <TextField
        variant="outlined"
        id={`Value ${answerIndex + 1}`}
        label="Value"
        style={{ width: '100px' }}
        value={answer.assessmentValue}
        size="small"
        type="number"
        onChange={(event) => onChangeAnswerAssessmentValue(event, questionIndex, answerIndex)}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />
    </Box>
  </Box>
));

type ConditionHeaderProps = {
  questionIndex: number;
  onClick: (questionIndex: number, type: ActionTypeEnum.ADD_CONDITION | ActionTypeEnum.REMOVE_CONDITION) => void;
};
const ConditionHeader: React.FC<ConditionHeaderProps> = Memo(({
  questionIndex,
  onClick,
}) => (
  <Box display="flex" margin="15px 0px">
    <IconButton onClick={() => onClick(questionIndex, ActionTypeEnum.ADD_CONDITION)}>
      <AddCircleOutlineIcon />
    </IconButton>
    <IconButton onClick={() => onClick(questionIndex, ActionTypeEnum.REMOVE_CONDITION)}>
      <RemoveCircleOutlineIcon />
    </IconButton>
    <Text as="h3">
      Conditions:
    </Text>
  </Box>
));

type SelectConditionQuestionProps = {
  condition: ConditionType;
  conditionIndex: number;
  questionIndex: number;
  onSelectConditionQuestion: (event: UnknownChangeEvent, conditionIndex: number, questionIndex: number) => void;
  selectableConditionQuestions: Array<{ index: number, question: AssessmentQuestionType }>;
};
const SelectConditionQuestion: React.FC<SelectConditionQuestionProps> = Memo(({
  condition,
  conditionIndex,
  questionIndex,
  onSelectConditionQuestion,
  selectableConditionQuestions,
}) => (
  <Box maxWidth="50%">
    <Text fontWeight="700">Depends on question:</Text>
    <Select
      labelId="select-question-condition"
      id="select-question-condition"
      native
      value={condition.question ?? ''}
      onChange={(event: UnknownChangeEvent) => onSelectConditionQuestion(event, conditionIndex, questionIndex)}
      autoWidth
      inputProps={{
        id: 'select-multiple-native',
      }}
    >
      <option value="">
        Select Question
      </option>
      {selectableConditionQuestions.map(({ index, question }) => (
        <option key={`${questionIndex}-${+conditionIndex}-${index}-question`} value={index}>
          {question.text || `Question #${index + 1}`}
        </option>
      ))}
    </Select>
  </Box>
));

type SelectConditionNegationProps = {
  condition: ConditionType;
  conditionIndex: number;
  questionIndex: number;
  onSelect: (event: UnknownChangeEvent, conditionIndex: number, questionIndex: number) => void;
};
const SelectConditionNegation: React.FC<SelectConditionNegationProps> = Memo(({
  condition,
  conditionIndex,
  questionIndex,
  onSelect,
}) => (
  <Box margin="0px 20px auto 0px" maxWidth="50%">
    <Text fontWeight="700">negation:</Text>
    <Select
      labelId="select-negation-condition"
      id="select-negation-condition"
      native
      value={condition.isNegated ?? ''}
      onChange={(event: UnknownChangeEvent) => onSelect(event, conditionIndex, questionIndex)}
      autoWidth
      inputProps={{
        id: 'select-multiple-native',
      }}
    >
      <option value="false">
        is
      </option>
      <option value="true">
        is not
      </option>
    </Select>
  </Box>
));

type SelectConditionAnswerProps = {
  condition: ConditionType;
  conditionIndex: number;
  questionIndex: number;
  onSelectConditionAnswer: (event: UnknownChangeEvent, conditionIndex: number, questionIndex: number) => void;
  selectableConditionAnswers: Array<{ index: number, answer: string }>;
};
const SelectConditionAnswer: React.FC<SelectConditionAnswerProps> = Memo(({
  condition,
  conditionIndex,
  questionIndex,
  onSelectConditionAnswer,
  selectableConditionAnswers,
}) => (
  <Box maxWidth="50%" marginLeft="20px">
    <Text fontWeight="700">answer:</Text>
    <Select
      labelId="select-answer-condition"
      id="select-answer-condition"
      native
      value={condition.answer ?? ''}
      onChange={(event: UnknownChangeEvent) => onSelectConditionAnswer(event, conditionIndex, questionIndex)}
      autoWidth
      inputProps={{
        id: 'select-multiple-native',
      }}
    >
      <option value="">
        Select Answer
      </option>
      {selectableConditionAnswers.map(({ index, answer }) => (
        <option key={`${questionIndex}-${+conditionIndex}-${index}-answer`} value={index}>
          {answer || `Answer #${index + 1}`}
        </option>
      ))}
    </Select>
  </Box>
));

type ConditionProps = {
  condition: ConditionType;
  conditionIndex: number;
  questionIndex: number;
  onSelectConditionQuestion: (event: UnknownChangeEvent, conditionIndex: number, questionIndex: number) => void;
  selectableConditionQuestions: Array<{ index: number, question: AssessmentQuestionType }>;
  onSelectConditionAnswer: (event: UnknownChangeEvent, conditionIndex: number, questionIndex: number) => void;
  selectableConditionAnswers: Array<{ index: number, answer: string }>;
  onSelectConditionNegation: (event: UnknownChangeEvent, conditionIndex: number, questionIndex:number) => void;
};
const Condition: React.FC<ConditionProps> = Memo(({
  condition,
  conditionIndex,
  questionIndex,
  onSelectConditionQuestion,
  selectableConditionQuestions,
  onSelectConditionAnswer,
  selectableConditionAnswers,
  onSelectConditionNegation,
}) => (
  <Box display="flex" margin="5px 0px">
    <SelectConditionQuestion
      condition={condition}
      conditionIndex={conditionIndex}
      questionIndex={questionIndex}
      onSelectConditionQuestion={onSelectConditionQuestion}
      selectableConditionQuestions={selectableConditionQuestions}
    />
    {(condition.question || condition.question === 0) && (
      <SelectConditionNegation
        condition={condition}
        conditionIndex={conditionIndex}
        questionIndex={questionIndex}
        onSelect={onSelectConditionNegation}
      />
    )}
    {(condition.question || condition.question === 0) && (
      <SelectConditionAnswer
        condition={condition}
        conditionIndex={conditionIndex}
        questionIndex={questionIndex}
        onSelectConditionAnswer={onSelectConditionAnswer}
        selectableConditionAnswers={selectableConditionAnswers}
      />
    )}
  </Box>
));

type AssessmentFooterProps = {
  onAddQuestion: () => void;
  onSave: () => void;
  saving: boolean;
};
const AssessmentFooter: React.FC<AssessmentFooterProps> = Memo(({
  onAddQuestion,
  onSave,
  saving,
}) => (
  <Box float="right">
    <Button variant="contained" color="default" onClick={onAddQuestion}>
      Add Question
    </Button>
    {' '}
    {!saving ? (
      <Button
        variant="contained"
        color="primary"
        onClick={onSave}
      >
        Save
      </Button>
    ) : (
      <>
        <LoadingSpinner />
        Saving...
      </>
    )}
  </Box>
));

export {
  ErrorMessage,
  AssessmentHeader,
  AssessmentFieldTextInput,
  IsArchivedSwitch,
  QuestionHeader,
  SetQuestionType,
  EditQuestionTextField,
  EditQuestionProperty,
  AnswerHeader,
  Answer,
  ConditionHeader,
  Condition,
  AssessmentFooter,
};
