import React from 'react';
import { Link, Box } from '@bighealth/react-limbix-ui';

import { formatDate } from '@/utils/dateUtils';

type AccessCodeUrlModalProps = {
  urls: string[];
}

const AccessCodeUrlModal: React.FC<AccessCodeUrlModalProps> = (props: AccessCodeUrlModalProps) => {
  const { urls } = props;
  const parseUrlForDate = (url: string) => {
    const re = new RegExp('access_codes_[0-9]*.[0-9]+', 'g');
    try {
      const csvName = url.match(re);
      const unixTime = csvName[0].split('_')[2];
      return formatDate(new Date(parseInt(unixTime, 10) * 1000));
    } catch (error) {
      return `CSV - No Name Found: ${error}`;
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      marginBottom="20px"
      maxHeight="350px"
      overflow="scroll"
    >
      Download Codes From:
      {!urls.length && (
        <Box>
          No Codes Found
        </Box>
      )}
      <ul>
        {urls.map((url) => (
          <li key={url}>
            <Link.ButtonLink onClick={() => window.open(url, '_blank')}>
              {parseUrlForDate(url)}
            </Link.ButtonLink>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default AccessCodeUrlModal;
