import React, { useState } from 'react';
import {
  MenuItem,
  MenuList,
  ClickAwayListener,
  Popper,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from '@bighealth/react-limbix-ui';

import Styled from './Header.styles';

import { UserType } from '@/apollo/types';
import { useWindowSize } from '@/utils/hooks';
import LimbixLogo from '@/assets/logo.svg';
import history from '@/utils/history';

type Props = {
  onLogout: () => void;
  limbixUser: UserType;
};
const Header: React.FC<Props> = (props: Props) => {
  const { onLogout, limbixUser } = props;
  const [menuRef, setMenuRef] = useState<HTMLDivElement>(null);
  const { width } = useWindowSize();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuRef(event.currentTarget);
  };

  const handleClosePopper = () => {
    setMenuRef(null);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleLogoutClick = () => {
    handleClosePopper();
    onLogout();
  };

  const handleSettingsClick = () => {
    handleClosePopper();
    history.push('/settings');
  };

  return (
    <>
      <Styled.AppBar>
        <Styled.Toolbar>
          <Styled.LinkContainer withborders={width > 500 ? 1 : 0}>
            <Link.RouterLink to="/">
              <Styled.LogoContainer>
                <Styled.Logo src={LimbixLogo} alt="limbix logo" />
              </Styled.LogoContainer>
            </Link.RouterLink>
          </Styled.LinkContainer>
          <Styled.DropdownTabContainer
            onClick={handleClick}
            role="button"
            tabIndex={0}
            withborders={width > 500 ? 1 : 0}
          >
            <Styled.DropdownTabText>
              <Styled.DropdownHeaderText>
                {`${limbixUser.email}`}
              </Styled.DropdownHeaderText>
            </Styled.DropdownTabText>
            <Styled.DropdownTabIcon>
              <ExpandMoreIcon />
            </Styled.DropdownTabIcon>
          </Styled.DropdownTabContainer>
        </Styled.Toolbar>
      </Styled.AppBar>
      <Popper open={!!menuRef} anchorEl={menuRef} placement="bottom-end">
        <ClickAwayListener onClickAway={handleClosePopper}>
          <Styled.PopperContainer>
            <MenuList
              autoFocusItem
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
              disablePadding
            >
              <MenuItem onClick={handleSettingsClick}>Settings</MenuItem>
              <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
            </MenuList>
          </Styled.PopperContainer>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default Header;
