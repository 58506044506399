import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { LoadingSpinner, Box } from '@bighealth/react-limbix-ui';
import useDeepCompareEffect from 'use-deep-compare-effect';

import PatientTable from './PatientTable';

import {
  QueryCareteamsArgs,
  MutationResetPatientAccessAttemptsArgs,
} from '@/apollo/types';
import { CARETEAMS_LIST } from '@/apollo/queries';
import { RESET_PATIENT_ACCESS_ATTEMPTS } from '@/apollo/mutations';
import { UseParamsType, MutationResultType } from '@/types';
import history from '@/utils/history';
import { useLazyQuery, useMutation } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import usePagination, { ColumnToOrderByMap, getDefaultPaginationState } from '@/hooks/usePagination';

const DEFAULT_PAGINATION_STATE = getDefaultPaginationState({
  columnId: 'accountCreation',
  direction: 'desc',
});

const columnToOrderByMap: ColumnToOrderByMap = {
  uid: 'patient__uid',
  email: 'patient__user__email',
  emailConfirmed: 'patient__user__email_verified',
  accountCreation: 'enrolled_time',
};

const PatientList: React.FC = () => {
  const { pdtUid } = useParams<UseParamsType>();
  const { state, pathname } = useLocation<{ searchFilter?: string }>();

  const [searchFilter, setSearchFilter] = useState(state?.searchFilter?.toLowerCase() || '');
  const [
    paginationState,
    paginationQueryVariables,
    updatePaginationState,
  ] = usePagination(
    DEFAULT_PAGINATION_STATE,
    columnToOrderByMap,
  );

  useEffect(() => {
    history.replace(pathname, null);
  }, []);

  const variables = {
    pdtUid,
    ...paginationQueryVariables,
  };

  const [
    careteamQuery,
    {
      loading,
      data,
      error,
      refetch,
    },
  ] = useLazyQuery<QueryCareteamsArgs>(CARETEAMS_LIST, {
    variables,
    fetchPolicy: 'no-cache',
  });

  useDeepCompareEffect(
    () => {
      careteamQuery({ variables });
    },
    [paginationState],
  );

  const [resetPatientAccessAttemptsMutation] = useMutation<MutationResetPatientAccessAttemptsArgs>(
    RESET_PATIENT_ACCESS_ATTEMPTS,
  );
  const resetPatientAccessAttempts = (patientUid: string): Promise<MutationResultType> => (
    resetPatientAccessAttemptsMutation({
      variables: {
        patientUid,
        pdtUid,
      },
    })
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/operations" />;
  }

  if (data?.careteams) {
    const { careteams } = data;
    const totalCount = careteams.length > 0 ? careteams[0].total : 0;
    return (
      <Box width="100%">
        <PatientTable
          pdtUid={pdtUid}
          careteams={data.careteams}
          resetPatientAccessAttempts={resetPatientAccessAttempts}
          searchString={searchFilter}
          onUpdateSearchString={setSearchFilter}
          paginationState={paginationState}
          updatePaginationState={updatePaginationState}
          totalCount={totalCount}
          onRefetch={refetch}
        />
      </Box>
    );
  }
  return (<></>);
};

export default PatientList;
