import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import {
  Table,
  Link,
  LoadingSpinner,
  Column,
  Row,
  SortState,
  Text,
  Box,
  createCell,
  createColumn, calculateNewSortState,
} from '@bighealth/react-limbix-ui';

import { QueryStudyParticipantArgs } from '@/apollo/types';
import { STUDY_PARTICIPANT_RESEARCH_ASSIGNMENTS_QUERY } from '@/apollo/queries';
import {
  UseParamsType,
  ResearchAssignmentType,
} from '@/types';
import { formatDate } from '@/utils/dateUtils';
import { useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import { usePermissions } from '@/hooks/redux';

const ResearchAssignments: React.FC = () => {
  const defaultSortState: SortState = { columnId: 'visibleDate', direction: 'asc' };
  const [sortState, setSortState] = useState(defaultSortState);
  const { studyUid, userUid: participantUid } = useParams<UseParamsType>();
  const { loading, data, error } = useQuery<QueryStudyParticipantArgs>(STUDY_PARTICIPANT_RESEARCH_ASSIGNMENTS_QUERY, {
    variables: {
      studyUid,
      participantUid,
    },
  });
  const [{ studyPermissions }] = usePermissions();
  const permissions = studyPermissions[studyUid];

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  const researchAssignments = data?.studyResearchAssignments?.map((assignment): ResearchAssignmentType => ({
    assessmentName: assignment.assessment?.name || 'Untitled',
    assignmentUid: assignment.uid,
    participantId: assignment.aboutStudyParticipant.participantId,
    aboutUserUid: assignment.aboutStudyParticipant?.user?.uid,
    assigneeUid: assignment.user?.uid,
    assigneeName: assignment.user ? `${assignment.user.firstName} ${assignment.user.lastName}` : 'Unassigned',
    status: assignment.status,
    visibleDate: assignment.visibleDate,
    dueDate: assignment.dueDate,
    clientType: assignment.typeOfClient,
    assignmentType: assignment.typeOfAssignment,
  }));

  if (permissions?.isBlinded) {
    return (
      <Text as="h1">
        You are blinded for this study and are not able to view Research Assignments.
      </Text>
    );
  }

  if (!researchAssignments || researchAssignments.length === 0) {
    return (
      <Text as="h1">
        There are no research assignments for the participant.
        <br />
        If that seems like a mistake please reach out to Research and Eng
      </Text>
    );
  }

  const columns: Column[] = [
    createColumn('assessmentName', 'Assessment Name', 'string', null, true),
    createColumn('assignee', 'Assignee', 'string', null, true),
    createColumn('participantId', 'Participant Id', 'string', null, true),
    createColumn('status', 'Status', 'string', null, true),
    createColumn('visibleDate', 'Visible Date', 'date', null, true),
    createColumn('dueDate', 'Due Date', 'date', null, true),
    createColumn('clientType', 'Client Type', 'string', null, true),
    createColumn('assignmentType', 'Assignment Type', 'string', null, true),
  ];

  const renderAssessmentName = (assessmentName: string, assignmentUid: string, aboutUserUid:string) => {
    const baseStudyUrl = `/research/study/${studyUid}`;
    const linkToAssignment = `${baseStudyUrl}/participant/${aboutUserUid}/research_assignments/${assignmentUid}`;
    return <Link.RouterLink to={linkToAssignment} fontSize="16px">{assessmentName || 'None'}</Link.RouterLink>;
  };

  const rows: Row[] = researchAssignments.map((assignment, index) => (
    {
      id: `${index}`,
      cells: [
        createCell(
          'assessmentName',
          assignment.assignmentUid,
          renderAssessmentName(assignment.assessmentName, assignment.assignmentUid, assignment.aboutUserUid),
        ),
        createCell('assignee', assignment.assigneeName),
        createCell('participantId', assignment.participantId),
        createCell('status', assignment.status),
        createCell('visibleDate', new Date(assignment.visibleDate), formatDate(assignment.visibleDate)),
        createCell('dueDate', new Date(assignment.dueDate), formatDate(assignment.dueDate)),
        createCell('clientType', assignment.clientType),
        createCell('assignmentType', assignment.assignmentType),
      ],
    } as Row
  ));

  return (
    <Box width="100%">
      <Table
        ariaLabel="deterioration_alerts"
        columns={columns}
        rows={rows}
        sortState={sortState}
        onSortByColumn={(colId) => setSortState(calculateNewSortState(colId, sortState))}
      />
    </Box>
  );
};

export default ResearchAssignments;
