import React, {
  useEffect,
  useReducer,
  useMemo,
  useCallback,
} from 'react';
import { Button } from '@material-ui/core';
import { Memo, Text, Box } from '@bighealth/react-limbix-ui';

import {
  ActionTypeEnum,
  StateType,
} from './AssignmentScheduleViewer.types';
import { reducer } from './AssignmentScheduleViewer.reducer';

import { AssignmentScheduleType } from '@/apollo/types';
import {
  AssessmentSwitch,
  Select,
  AssignmentScheduleCalendar,
} from '@/components';
import { groupBy } from '@/utils/arrayUtils';
import { DISTINCT_COLOR_PALETTE } from '@/utils/constants';
import { AccountType, UnknownChangeEvent, AssignmentPathInfo } from '@/types';

const organizeAssignmentSchedules = (
  assignmentSchedules: Array<AssignmentScheduleType>,
  accountType: AccountType,
): Record<string, AssignmentScheduleType[]> => {
  const filteredAssignmentSchedules = assignmentSchedules.filter((assignmentSchedule) => (
    assignmentSchedule.accountType?.toString() === accountType.toString()
  ));

  const sortedAssignmentSchedules = filteredAssignmentSchedules.sort((a, b) => (
    (a.startTimeOffsetDays || 0) - (b.startTimeOffsetDays || 0)
  ));

  return groupBy(
    sortedAssignmentSchedules,
    (assignmentSchedule: AssignmentScheduleType) => (
      assignmentSchedule?.assessment?.name || assignmentSchedule.typeForAssignment
    ),
  );
};

const getScheduleFill = (assessmentIndex: number, colorPalette: string[]) => (
  colorPalette[assessmentIndex % colorPalette.length]
);

/*
  When an SVG element is being hovered on it needs to be brought to the front of the SVG so it
  can appear "in front of" all the other SVGs, otherwise it wont appear like its expanding if part
  of it is hidden behind other schedule paths. This is accomplished by popping it out of the array and
  placing it back in at the very end.
*/
/**
 * Returns a modified copy of assignmentPathInfo that pulls a path to the front and changes its appearance
 * such that it appears to have 'expanded' on hover
*/
const bringPathToFront = (
  assignmentPathInfo: AssignmentPathInfo[],
  pathKey: string,
) => {
  const assignmentPathInfoCopy = [...assignmentPathInfo];
  const pathBeingHoveredIndex = assignmentPathInfoCopy.findIndex((path) => (path.key === pathKey));
  const pathBeingHoveredObject = { ...assignmentPathInfoCopy.splice(pathBeingHoveredIndex, 1)[0] };
  pathBeingHoveredObject.rect = pathBeingHoveredObject.rect.map((rect) => ({
    ...rect,
    y: rect.y - 4,
    height: rect.height + 8,
    fillOpacity: 1.0,
  }));
  assignmentPathInfoCopy.push(pathBeingHoveredObject);
  return assignmentPathInfoCopy;
};

const initialState = {
  accountTypeFilter: AccountType.Adult,
  assessmentFilters: {} as Record<string, boolean>,
  pathBeingHovered: null as string,
  calendarHeight: 0,
  cellSize: 40,
  generatedSchedules: [],
  colorPalette: DISTINCT_COLOR_PALETTE,
  assignmentPathInfo: [],
} as StateType;

type AssignmentScheduleViewerProps = {
  assignmentSchedules: Array<AssignmentScheduleType>;
};
const AssignmentScheduleViewer: React.FC<AssignmentScheduleViewerProps> = (
  props: AssignmentScheduleViewerProps,
) => {
  const { assignmentSchedules } = props;
  const [{
    accountTypeFilter,
    assessmentFilters,
    pathBeingHovered,
    calendarHeight,
    cellSize,
    generatedSchedules,
    colorPalette,
    assignmentPathInfo,
  }, dispatch] = useReducer(reducer, initialState);

  const listOfAssessments = useMemo(
    () => Object.keys(
      groupBy(
        assignmentSchedules,
        (assignmentSchedule: AssignmentScheduleType) => (
          assignmentSchedule?.assessment?.name || assignmentSchedule.typeForAssignment
        ),
      ),
    ),
    [assignmentSchedules],
  );

  const numberOfAssessmentsTotal = Object.keys(listOfAssessments)?.length ?? 0;
  const assignmentSchedulesEmpty = !Object.keys(assessmentFilters)?.length;
  const calendarWidth = cellSize * 7;

  const handleHover = useCallback((key: string) => {
    dispatch({ type: ActionTypeEnum.SET_PATH_BEING_HOVERED, value: key });
  }, []);

  // First use effect is for recalculating the entire calendar in the event that the assignmentSchedules prop changes
  // or if the accountTypeFilter changes
  useEffect(() => {
    const organizedAssignmentSchedules = organizeAssignmentSchedules(assignmentSchedules, accountTypeFilter);

    // 10px per assessment and + 40 to add a buffer to the top and bottom of each cell to prevent crowding
    // take a max to ensure the cell is never less than 140px for
    const calculatedCellSize = Math.max(100, (numberOfAssessmentsTotal * 10)) + 40;

    dispatch({
      type: ActionTypeEnum.SET_CELL_SIZE,
      value: calculatedCellSize,
    });
    dispatch({
      type: ActionTypeEnum.GENERATE_SCHEDULES,
      assignmentSchedules: organizedAssignmentSchedules,
    });
    dispatch({
      type: ActionTypeEnum.CALCULATE_CALENDAR_HEIGHT,
    });
    dispatch({
      type: ActionTypeEnum.REPLACE_ASSESSMENT_FILTERS,
      assessmentFilters: Object.keys(organizedAssignmentSchedules),
    });
    dispatch({ type: ActionTypeEnum.SET_PATH_BEING_HOVERED, value: null });
  }, [assignmentSchedules, accountTypeFilter]);

  // Second use effect is for resetting pathBeingHovered and all path info for the schedules when an assessment
  // filter changes due to a switch being flipped
  useEffect(() => {
    dispatch({ type: ActionTypeEnum.SET_PATH_BEING_HOVERED, value: null });
    dispatch({
      type: ActionTypeEnum.GENERATE_ASSIGNMENT_PATH_INFO,
      handleHover,
    });
  }, [assessmentFilters]);

  const handleSwitchFlip = useCallback(
    (assessment: string) => () => {
      dispatch({
        type: ActionTypeEnum.FLIP_ASSESSMENT_FILTER,
        assessment,
      });
    },
    [],
  );

  const handleSelectAccountType = useCallback((event: UnknownChangeEvent) => (
    dispatch({
      type: ActionTypeEnum.UPDATE_ACCOUNT_TYPE_FILTER,
      value: `${event.target.value}` as AccountType,
    })
  ), []);

  const handleClickShowNoneButton = useCallback(() => {
    dispatch({ type: ActionTypeEnum.UNAPPLY_ALL_ASSESSMENT_FILTERS });
  }, []);

  const handleClickShowAllButton = useCallback(() => {
    dispatch({ type: ActionTypeEnum.APPLY_ALL_ASSESSMENT_FILTERS });
  }, []);

  const finalAssignmentPathInfo = (pathBeingHovered && assignmentPathInfo?.length)
    ? bringPathToFront(assignmentPathInfo, pathBeingHovered)
    : assignmentPathInfo;

  return (
    <Box display="flex" width="100%" height="100%" paddingBottom="25px">
      <Box marginBottom="10px" width="40%">
        <Select.Enum
          value={accountTypeFilter}
          enumType={AccountType}
          label="Account Type: "
          onSelect={handleSelectAccountType}
        />
        <Box alignItems="left" marginBottom="12px">
          {generatedSchedules.map((schedule, index) => (
            <AssessmentSwitch
              key={schedule.name}
              checked={!!assessmentFilters[schedule.name]}
              assessmentName={schedule.name}
              tagColor={getScheduleFill(index, colorPalette)}
              onSwitchFlip={handleSwitchFlip(schedule.name)}
            />
          ))}
        </Box>
        {!assignmentSchedulesEmpty && (
          <Box display="inline-flex">
            <Box marginRight="10px">
              <Button
                onClick={handleClickShowNoneButton}
                variant="contained"
                color="default"
              >
                Show None
              </Button>
            </Box>
            <Box marginLeft="10px">
              <Button
                onClick={handleClickShowAllButton}
                variant="contained"
                color="primary"
              >
                Show All
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      {assignmentSchedulesEmpty ? (
        <Box>
          <Text as="h1">
            {`No Assignment Schedules for Account Type: ${accountTypeFilter}`}
          </Text>
        </Box>
      ) : (
        <AssignmentScheduleCalendar
          cellSize={cellSize}
          calendarWidth={calendarWidth}
          calendarHeight={calendarHeight}
          assignmentPathInfo={finalAssignmentPathInfo}
        />
      )}
    </Box>
  );
};

export default Memo(AssignmentScheduleViewer);
