import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AssessmentForm,
  LoadingSpinner,
  Box,
  Text,
  Question,
} from '@bighealth/react-limbix-ui';

import { FormControlLabel, Switch } from '@material-ui/core';

import {
  LanguageType, LimbixUserLanguage, QueryAssessmentArgs,
} from '@/apollo/types';
import { UnknownChangeEvent, UseParamsType } from '@/types';
import { ASSESSMENT_QUERY } from '@/apollo/queries';
import { useQuery } from '@/hooks/apollo';
import { useModal } from '@/hooks/redux';
import { DEFAULT_LANGUAGE } from '@/utils/constants';
import { Select } from '@/components';

const TestAssessment: React.FC = () => {
  const { assessmentUid } = useParams<UseParamsType>();
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const [withFallback, setWithFallback] = useState(false);

  const { data, loading } = useQuery<QueryAssessmentArgs>(ASSESSMENT_QUERY, {
    variables: {
      assessmentUid,
      language,
      withFallback,
    },
  });
  const { showModal } = useModal();

  const renderSkippedQuestionsModal = (
    skippedQuestions: Question[],
    onSkip: () => void, onReturn?:
    () => void,
  ) => {
    const questionsMissingAnswers = skippedQuestions.map((question) => (
      <Box key={question.uid}>
        <Text as="span">{question.text}</Text>
        <Text as="span" color="red">{question.isOptional ? '' : ' *'}</Text>
      </Box>
    ));
    showModal(
      'GENERIC_WARNING',
      {
        headerText: 'Are you sure you want to skip this question?',
        body: questionsMissingAnswers,
        primaryButtonText: 'Return to survey',
        secondaryButtonText: 'Skip questions and submit',
        onClickPrimaryButton: onReturn,
        onClickSecondaryButton: onSkip,
      },
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (data?.assessment) {
    return (
      <Box>
        <Box marginRight="20px" marginBottom="20px">
          Select a language to view the assessment in that language.
          <Select.Language
            currentLanguage={language as unknown as LimbixUserLanguage}
            onChange={(event: UnknownChangeEvent) => {
              setLanguage(event.target.value as LanguageType);
            }}
          />
          Missing translations are rendered as a blank area unless Fallback to English is enabled.
          <Box>
            <FormControlLabel
              value="start"
              control={(
                <Switch
                  color="primary"
                  checked={withFallback}
                  value={withFallback}
                  onChange={(_, checked) => setWithFallback(checked)}
                  disabled={language === LanguageType.En}
                />
              )}
              label="View as participant (fallback to English)"
              labelPlacement="end"
            />
          </Box>

        </Box>
        <hr />
        <Text as="h2">
          {data.assessment.name}
        </Text>
        <AssessmentForm
          assessment={data.assessment}
          canSkipQuestions
          onSubmit={async () => console.log('submitted')}
          onCancel={async () => console.log('cancel')}
          onSubmitWithSkippedQuestions={renderSkippedQuestionsModal}
        />
      </Box>
    );
  }

  return (<></>);
};

export default TestAssessment;
