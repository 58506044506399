import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Paper, Divider } from '@material-ui/core';
import { LoadingSpinner, Box } from '@bighealth/react-limbix-ui';

import Styled from './Details.styles';

import { Card } from '@/components';
import { QueryStudyParticipantArgs } from '@/apollo/types';
import { STUDY_PARTICIPANT_QUERY, STUDY_ASSIGNMENTS_WITH_RESULTS_QUERY } from '@/apollo/queries';
import { UseParamsType } from '@/types';
import { useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const Details: React.FC = () => {
  const { studyUid, userUid } = useParams<UseParamsType>();
  const variables = {
    studyUid,
    userUid,
  };
  const assignments = useQuery<QueryStudyParticipantArgs>(STUDY_ASSIGNMENTS_WITH_RESULTS_QUERY, { variables });
  const { loading, data, error } = useQuery<QueryStudyParticipantArgs>(STUDY_PARTICIPANT_QUERY, { variables });

  if (loading || assignments.loading) {
    return <LoadingSpinner />;
  }

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/research" />;
  }

  const {
    studyParticipant,
    studyParticipant: {
      studyArm,
      user,
    },
  } = data;

  const numberOfWeeks = !studyArm?.isInitialArm ? 12 : 6;

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Box width="95%">
        <Paper elevation={2}>
          <Styled.PaperDiv>
            <Card.ParticipantInfo studyParticipant={studyParticipant} studyUid={studyUid} />
            <Divider />
            {user.accountType !== 'PARENT' && (
              <Box width="100%">
                <Card.PHQData
                  studyUid={studyUid}
                  userUid={userUid}
                  numberOfWeeks={numberOfWeeks}
                  assignments={assignments?.data?.studyAssignments || []}
                  baselinePhqScore={user?.baselinePhqScore}
                />
              </Box>
            )}
          </Styled.PaperDiv>
        </Paper>
      </Box>
    </Box>
  );
};

export default Details;
