import React, { useState } from 'react';
import {
  Table,
  Link,
  LoadingSpinner,
  Column,
  Row,
  SortState,
  Text,
  Box,
  calculateNewSortState,
  createCell,
  createColumn,
} from '@bighealth/react-limbix-ui';

import ChangeLogDownloadLink from './ChangeLogDownloadLink';

import { LimbixStudyType } from '@/apollo/types';
import { STUDY_LIST_QUERY } from '@/apollo/queries';
import { formatDate } from '@/utils/dateUtils';
import { useQuery } from '@/hooks/apollo';
import StudyActionLinks from '@/components/StudyActionLinks';

const StudyList: React.FC = () => {
  const defaultSortState: SortState = { columnId: 'startTime', direction: 'desc' };
  const [sortState, setSortState] = useState(defaultSortState);
  const { data, loading, error } = useQuery(STUDY_LIST_QUERY);
  if (loading) {
    return <LoadingSpinner />;
  }

  if (!error && data.limbixStudies) {
    const { limbixStudies } = data;
    if (limbixStudies.length === 0) {
      return (
        <Text as="h1">
          {'It seems like you don\'t have access to any studies'}
          <br />
          Please reach out to Research or Eng if you think this is a mistake
        </Text>
      );
    }

    const baseStudyUrl = (studyUid: string) => (`/research/study/${studyUid}`);

    const renderActions = (study: LimbixStudyType) => (
      <>
        <Link.RouterLink underlined to={baseStudyUrl(study.uid)}>Manage Participants</Link.RouterLink>
        {' | '}
        <Link.RouterLink underlined to={`${baseStudyUrl(study.uid)}/settings`}>Edit Settings</Link.RouterLink>
        {' | '}
        <ChangeLogDownloadLink studyUid={study.uid} />
      </>
    );

    const columns: Column[] = [
      createColumn('studyUid', 'UID', 'string', '5%', true),
      createColumn('studyName', 'Name', 'string', '15%', true),
      createColumn('startTime', 'Start Time', 'date', '15%', true),
      createColumn('actions', 'Actions', 'string', '20%', false),
      createColumn('view', 'View', 'string', '45%', false),
    ];

    const rows: Row[] = limbixStudies.map((limbixStudy) => (
      {
        id: limbixStudy.uid,
        cells: [
          createCell('studyUid', limbixStudy.uid, limbixStudy.uid.substring(0, 6)),
          createCell('studyName', limbixStudy.name, limbixStudy.name || 'None'),
          createCell('startTime', new Date(limbixStudy.startTime), formatDate(limbixStudy.startTime)),
          createCell('actions', 'actions', renderActions(limbixStudy)),
          createCell('view', 'view', <StudyActionLinks study={limbixStudy} />),
        ],
      } as Row
    ));

    return (
      <Box>
        <Table
          ariaLabel="study_list"
          columns={columns}
          rows={rows}
          sortState={sortState}
          onSortByColumn={(colId) => setSortState(calculateNewSortState(colId, sortState))}
        />
      </Box>
    );
  }

  return <></>;
};

export default StudyList;
