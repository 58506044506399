import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { LoadingSpinner, Box } from '@bighealth/react-limbix-ui';

import FreeFormTextReview from '../FreeFormTextReview';

import { QueryCareteamsArgs } from '@/apollo/types';
import { CARETEAM_PATIENT_INFO_QUERY } from '@/apollo/queries';
import { UseParamsType } from '@/types';
import { useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

const PatientInfo: React.FC = () => {
  const { pdtUid, patientUid } = useParams<UseParamsType>();

  const { loading, data, error } = useQuery<QueryCareteamsArgs>(CARETEAM_PATIENT_INFO_QUERY, {
    variables: {
      pdtUid,
      patientUid,
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const careteam = data?.careteam;
  const userUid = careteam.patient.user.uid;

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/operations" />;
  }

  if (careteam) {
    return (
      <Box width="100%">
        <FreeFormTextReview userUid={userUid} />
      </Box>
    );
  }
  return (<></>);
};

export default PatientInfo;
