import React, { useCallback, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import {
  StaticDatePicker,
  PaginatedTable,
  LoadingSpinner,
  Column,
  Row,
  Text,
  Box,
  calculateNewSortState,
  createCell,
  createColumn,
} from '@bighealth/react-limbix-ui';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { QueryFreeFormTextsArgs } from '@/apollo/types';
import { FREE_FORM_TEXTS } from '@/apollo/queries';
import {
  UseParamsType,
} from '@/types';
import { formatDate } from '@/utils/dateUtils';
import usePagination, { ColumnToOrderByMap, getDefaultPaginationState } from '@/hooks/usePagination';
import { useLazyQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';

type Props = {
  userUid: string;
};

const DEFAULT_PAGINATION_STATE = getDefaultPaginationState(
  { columnId: 'dateCreated', direction: 'asc' },
);

const columnToOrderByMap: ColumnToOrderByMap = {
  dateCreated: 'created_at',
  prompt: 'prompt',
  text: 'value',
};

const FreeFormTextReview: React.FC<Props> = (props: Props) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { pdtUid } = useParams<UseParamsType>();
  const { userUid } = props;

  const [paginationState, paginationQueryVariables, updatePaginationState] = usePagination(
    DEFAULT_PAGINATION_STATE,
    columnToOrderByMap,
  );
  const variables = {
    pdtUid,
    userUid,
    date: selectedDate,
    ...paginationQueryVariables,
  };

  const [fftQuery, { loading, data, error }] = useLazyQuery<QueryFreeFormTextsArgs>(FREE_FORM_TEXTS, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const handleChangeDate = useCallback((date: Date) => {
    setSelectedDate(date);
    // reset table state
    updatePaginationState(DEFAULT_PAGINATION_STATE);
  }, []);

  useDeepCompareEffect(
    () => {
      fftQuery({ variables });
    },
    [paginationState, selectedDate],
  );

  if (isPermissionDeniedError(error)) {
    return <Redirect to="/operations" />;
  }

  const renderFreeFormTextTable = () => {
    if (loading || !data) {
      return <LoadingSpinner />;
    }
    if (data.freeFormTexts?.length === 0) {
      return (
        <Text as="h1">
          {'It seems there aren\'t any free form texts for this date'}
          <br />
          If that seems like a mistake please reach out to Research and Eng
        </Text>
      );
    }

    const { freeFormTexts } = data;
    const totalCount = freeFormTexts.length > 0 ? freeFormTexts[0].total : 0;

    const columns: Column[] = [
      createColumn('dateCreated', 'Date Created', 'date', null, true),
      createColumn('prompt', 'Prompt', 'string', null, true),
      createColumn('text', 'Text', 'string', null, true),
    ];

    const rows: Row[] = freeFormTexts.map((fft, index) => {
      const row: Row = {
        id: `${index}`,
        cells: [
          createCell('dateCreated', new Date(fft.createdAt), formatDate(fft.createdAt)),
          createCell('prompt', fft.prompt),
          createCell('text', fft.value),
        ],
      };
      return row;
    });

    return (
      <Box width="100%">
        <PaginatedTable
          ariaLabel="freeform_text_review"
          columns={columns}
          rows={rows}
          sortState={paginationState.sortState}
          onSortByColumn={(colId) => updatePaginationState({
            sortState: calculateNewSortState(colId, paginationState.sortState),
          })}
          currentPage={paginationState.currentPage}
          onGoToPage={(pageNum) => updatePaginationState({ currentPage: pageNum })}
          itemsPerPage={paginationState.itemsPerPage}
          totalCount={totalCount}
          skipRowSort
        />
      </Box>
    );
  };

  return (
    <Box width="100%" display="flex">
      <Box zIndex="0">
        <StaticDatePicker selectedDate={selectedDate} onChange={handleChangeDate} />
      </Box>
      <Box
        style={{
          width: 'calc(100% - 350px)',
          display: 'inline',
          zIndex: 0,
          marginLeft: '40px',
        }}
      >
        {renderFreeFormTextTable()}
      </Box>
    </Box>
  );
};

export default FreeFormTextReview;
