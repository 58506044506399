import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Link, PHQChart, PHQData as PHQDataType } from '@bighealth/react-limbix-ui';

import Styled from './PHQData.styles';

import { formatDate } from '@/utils/dateUtils';
import { useWindowSize } from '@/utils/hooks';
import { AssignmentType } from '@/apollo/types';
import { resultHasSkippedQuestions } from '@/utils/assessmentUtils';

type PHQDataWithUID = PHQDataType & { uid: string };

type Props = {
  assignments: AssignmentType[],
  userUid: string,
  studyUid: string,
  numberOfWeeks: number,
  baselinePhqScore: number,
}

const PHQData: React.FC<Props> = (props: Props) => {
  const {
    assignments,
    userUid,
    studyUid,
    numberOfWeeks,
    baselinePhqScore,
  } = props;

  const [selectedPHQ, setSelectedPHQ] = useState<PHQDataType>(null);
  const { width: windowWidth } = useWindowSize();

  const completePHQs: PHQDataWithUID[] = assignments?.filter((assignment) => (
    assignment.participantAssessmentResult !== null && assignment.completeTime !== null
  )).filter((assignment) => (
    assignment?.participantAssessmentResult?.assessment?.name.toLowerCase().includes('phq')
  )).map((assignment) => ({
    score: assignment.participantAssessmentResult.score,
    hasSkippedQuestions: resultHasSkippedQuestions(assignment.participantAssessmentResult),
    completeTime: assignment.completeTime,
    uid: assignment.uid,
  }));

  completePHQs.sort((a, b) => (
    a.completeTime > b.completeTime ? 1 : -1
  ));

  if (completePHQs.length === 0) {
    return (
      <h1>
        {'It seems there aren\'t any Complete PHQ assignments for this participant'}
        <br />
        If that seems like a mistake please reach out to Research and Eng
      </h1>
    );
  }

  if (!selectedPHQ && completePHQs.length > 0) {
    setSelectedPHQ(completePHQs[completePHQs.length - 1]);
  }

  const handleSelectPHQ = (phq: PHQDataType) => {
    setSelectedPHQ(phq);
  };

  const BASE_URL = `/research/study/${studyUid}/participant/${userUid}/assignments`;

  return (
    <Styled.ChartContainer>
      <Styled.PHQHeaderContainer>
        <Styled.BoldText>
          Weekly PHQ-8
        </Styled.BoldText>
        <Styled.PHQBaselineText>
          {`Baseline PHQ-8: ${baselinePhqScore ?? '-'}`}
        </Styled.PHQBaselineText>
      </Styled.PHQHeaderContainer>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="stretch"
        style={{ height: '100%' }}
      >
        <Grid item sm={12} lg={3}>
          <Styled.OrderedList>
            {completePHQs
              .map((phq) => (
                <li key={phq.uid}>
                  <div>
                    {`PHQ-8 Score: ${phq?.score ?? '-'}`}
                  </div>
                  <div>
                    {`Completed: ${formatDate(phq.completeTime)}`}
                  </div>
                  <div>
                    <Link.RouterLink to={`${BASE_URL}/${phq.uid}`}>
                      View Answers
                    </Link.RouterLink>
                  </div>
                  <br />
                </li>
              ))}
          </Styled.OrderedList>
        </Grid>
        {windowWidth > 750 && (
          <Grid item sm={12} lg={8}>
            <Styled.ChartDiv>
              <PHQChart
                chartId={userUid}
                numberOfWeeks={numberOfWeeks}
                phqs={completePHQs}
                selectedPHQ={selectedPHQ}
                onSelectPHQ={handleSelectPHQ}
                height={475}
              />
            </Styled.ChartDiv>
          </Grid>
        )}
      </Grid>
    </Styled.ChartContainer>
  );
};

export default PHQData;
