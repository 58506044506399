import React, { useMemo } from 'react';
import { Memo, Box } from '@bighealth/react-limbix-ui';

import { WeekGridRow, AssignmentSchedulePath } from './AssignmentScheduleCalendar.components';

import { AssignmentPathInfo } from '@/types';

type AssignmentScheduleCalendarProps = {
  calendarWidth: number,
  calendarHeight: number,
  cellSize: number,
  assignmentPathInfo: AssignmentPathInfo[],
}
const AssignmentScheduleCalendar: React.FC<AssignmentScheduleCalendarProps> = (
  props: AssignmentScheduleCalendarProps,
) => {
  const {
    calendarWidth,
    calendarHeight,
    cellSize,
    assignmentPathInfo,
  } = props;

  const weekGridCells = useMemo(
    () => Array(calendarHeight / cellSize).fill(null).map((_, index) => (
      <WeekGridRow key={+index} weekNumber={index} cellSize={cellSize} />
    )),
    [calendarHeight, cellSize],
  );

  return (
    <Box width="70%" maxWidth="1400px" height="100%" overflow="scroll">
      <svg
        viewBox={`0 0 ${calendarWidth} ${calendarHeight}`}
        width="100%"
        x={1}
        y={1}
        xmlns="http://www.w3.org/2000/svg"
      >
        {weekGridCells}
        <path
          d={`M 0 0 L ${calendarWidth} 0 L ${calendarWidth} ${calendarHeight} L 0 ${calendarHeight} z`}
          fillOpacity="0"
          stroke="#222222"
          strokeWidth="5"
        />
        {assignmentPathInfo.map((pathInfo) => (
          <AssignmentSchedulePath key={pathInfo.key} assignmentPathInfo={pathInfo} />
        ))}
      </svg>
    </Box>
  );
};

export default Memo(AssignmentScheduleCalendar);
