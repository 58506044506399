import React, { useContext, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Link, LoadingSpinner } from '@bighealth/react-limbix-ui';

import Styled from '../Modal.styles';

import {
  PractitionerAccessCodeType,
  MutationResendAccessCodeArgs,
  MutationRevokeAccessCodeArgs,
  MutationIssueNewAccessCodeArgs,
} from '@/apollo/types';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import {
  RESEND_ACCESS_CODE,
  REVOKE_ACCESS_CODE,
  ISSUE_NEW_ACCESS_CODE,
} from '@/apollo/mutations';
import { formatDate } from '@/utils/dateUtils';
import { formatPractitionerName } from '@/utils/stringUtils';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';
import { ModalLoadingContext } from '@/components/Modal/Modal';

type Props = {
  payload: ModalPayloadType['ACCESS_CODES'];
  onClose: () => void;
};

const AccessCodes: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    practitioner,
    pdtUid,
    onRefetch,
  } = payload;

  const [sendingAccessCode, setSendingAccessCode] = useState(false);
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const [isRevokingAccessCode, setIsRevokingAccessCode] = useState(false);
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);
  const { modalLoading, setModalLoading } = useContext(ModalLoadingContext);

  const currentAccessCode = practitioner.accessCodes.find((accessCode) => (!accessCode.expirationTime));

  const [resendAccessCodeMutation] = useMutation<MutationResendAccessCodeArgs>(
    RESEND_ACCESS_CODE,
  );
  const resendAccessCode = (): Promise<MutationResultType> => (
    resendAccessCodeMutation({
      variables: {
        practitionerUid: practitioner.uid,
        pdtUid,
      },
    })
  );

  const [revokeAccessCodeMutation] = useMutation<MutationRevokeAccessCodeArgs>(
    REVOKE_ACCESS_CODE,
  );
  const revokeAccessCode = async (changeReason: string): Promise<MutationResultType> => {
    setModalLoading(true);
    const result = await revokeAccessCodeMutation({
      variables: {
        practitionerUid: practitioner.uid,
        pdtUid,
        changeReason,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    setModalLoading(false);
    return result;
  };

  const [issueNewAccessCodeMutation] = useMutation<MutationIssueNewAccessCodeArgs>(
    ISSUE_NEW_ACCESS_CODE,
  );
  const issueNewAccessCode = async (): Promise<MutationResultType> => {
    setModalLoading(true);
    const result = await issueNewAccessCodeMutation({
      variables: {
        practitionerUid: practitioner.uid,
        pdtUid,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    setModalLoading(false);
    return result;
  };

  const triggerResendAccessCode = async () => {
    const { errors: resultErrors } = await resendAccessCode();
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      setSendingAccessCode(true);
      setTimeout(() => setSendingAccessCode(false), 2000);
    }
  };

  const triggerIssueNewAccessCode = async () => {
    const { errors: resultErrors } = await issueNewAccessCode();
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  const triggerRevokeAccessCode = async () => {
    const { errors: resultErrors } = await revokeAccessCode(changeReasonValue);
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      setIsRevokingAccessCode(false);
      onClose();
    }
  };

  const renderCode = (accessCode: PractitionerAccessCodeType) => {
    if (!accessCode.expirationTime) {
      return (
        <li key={accessCode.code}>
          Code:
          {` ${accessCode.code}`}
          <ul>
            <li>
              <Link.ButtonLink onClick={triggerResendAccessCode}>
                Resend Access Code
              </Link.ButtonLink>
              {sendingAccessCode && (
                <>
                  {' sent!'}
                </>
              )}
            </li>
            <li>
              <Link.ButtonLink onClick={() => setIsRevokingAccessCode(true)}>
                Revoke Access Code
              </Link.ButtonLink>
            </li>
          </ul>
        </li>
      );
    }
    return (
      <li key={accessCode.code} style={{ color: 'red' }}>
        Code:
        {` ${accessCode.code}`}
        <ul>
          <li style={{ color: 'red' }}>
            Expiration Time:
            {` ${formatDate(accessCode.expirationTime)}`}
          </li>
        </ul>
      </li>
    );
  };

  const viewAccessCodes = (
    <div style={{ width: '100%', height: '90%' }}>
      <div style={{ width: '100%' }}>
        <Styled.HeaderText style={{ marginBottom: 5 }}>
          View Practitioner Access Codes
        </Styled.HeaderText>
        <Styled.BodyText>
          Practitioner:
          {` ${formatPractitionerName(practitioner)}`}
        </Styled.BodyText>
        <div style={{ overflow: 'scroll', height: '70%' }}>
          <ul>
            {practitioner.accessCodes.map((accessCode) => renderCode(accessCode))}
          </ul>
        </div>
      </div>
      <Styled.ButtonContainer>
        <Styled.NoButton onClick={onClose}>
          <Styled.NoButtonText>
            Close
          </Styled.NoButtonText>
        </Styled.NoButton>
        {!currentAccessCode && (
          <Styled.YesButton onClick={triggerIssueNewAccessCode}>
            <Styled.YesButtonText>
              Issue Access Code
            </Styled.YesButtonText>
          </Styled.YesButton>
        )}
      </Styled.ButtonContainer>
    </div>
  );

  const revokeCurrentAccessCode = (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <Styled.HeaderText style={{ marginBottom: 5 }}>
          Revoke Current Access Code
        </Styled.HeaderText>
        <Styled.BodyText>
          Practitioner:
          {` ${formatPractitionerName(practitioner)}`}
          <br />
          Pdt:
          {` ${currentAccessCode?.pdt?.name}`}
          <br />
          Access Code:
          {` ${currentAccessCode?.code}`}
        </Styled.BodyText>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="change reason"
          label="Change Reason"
          id="change reason"
          onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
        />
      </div>
      <Styled.ButtonContainer>
        <Styled.NoButton onClick={() => {
          setIsRevokingAccessCode(false);
          setErrors([]);
        }}
        >
          <Styled.NoButtonText>
            Close
          </Styled.NoButtonText>
        </Styled.NoButton>
        <Styled.YesButton onClick={triggerRevokeAccessCode} disabled={!changeReasonValue}>
          <Styled.YesButtonText>
            Revoke Code
          </Styled.YesButtonText>
        </Styled.YesButton>
      </Styled.ButtonContainer>
    </div>
  );

  if (modalLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ width: '416px', height: '475px' }}>
      <Styled.DialogContent>
        {!isRevokingAccessCode ? viewAccessCodes : revokeCurrentAccessCode}
        {errors.map((error) => (
          <div key={error.message} style={{ color: 'red' }}>
            {error.message}
          </div>
        ))}
      </Styled.DialogContent>
    </div>
  );
};

export default AccessCodes;
