import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from '@bighealth/react-limbix-ui';

import Styled from '../Modal.styles';

import { PractitionerType } from '@/apollo/types';
import { formatPractitionerName } from '@/utils/stringUtils';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['VIEW_PROVIDERS'];
  onClose: () => void;
};

const ViewProviders: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const [selectedPractitioner, setSelectedPractitioner] = useState({
    clicked: false,
    searchFilter: null as string,
  });

  const close = () => {
    onClose();
  };

  const {
    careteam,
  } = payload;

  if (selectedPractitioner.clicked) {
    const basePDTUrl = `/operations/pdt/${careteam.pdt.uid}`;
    const linkToUID = `${basePDTUrl}/provider`;
    close();
    return (
      <Redirect to={{ pathname: linkToUID, state: selectedPractitioner }} />
    );
  }

  const renderName = (practitioner: PractitionerType) => (
    <li key={practitioner.uid}>
      <Link.ButtonLink
        onClick={() => setSelectedPractitioner({ clicked: true, searchFilter: practitioner.user.email })}
        fontSize="16px"
      >
        {formatPractitionerName(practitioner)}
      </Link.ButtonLink>
    </li>
  );

  return (
    <div style={{ width: '350px', height: '325px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.TextContainer>
            <Styled.HeaderText>
              View Providers for
              <br />
              {careteam.patient.uid?.substring(0, 6)}
            </Styled.HeaderText>
            <ul>
              {careteam.practitioners?.length > 0 && (
                careteam.practitioners.map((practitioner) => renderName(practitioner))
              )}
            </ul>
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={close}>
            <Styled.NoButtonText>
              Close
            </Styled.NoButtonText>
          </Styled.NoButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default ViewProviders;
