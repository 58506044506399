import React, { useContext, useState } from 'react';
import { TextField } from '@material-ui/core';
import { LoadingSpinner } from '@bighealth/react-limbix-ui';

import Styled from '../Modal.styles';

import { MutationTransitionToAdultArgs } from '@/apollo/types';
import { TRANSITION_TO_ADULT } from '@/apollo/mutations';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';
import { ModalLoadingContext } from '@/components/Modal/Modal';

type Props = {
  payload: ModalPayloadType['TRANSITION_TO_ADULT'];
  onClose: () => void;
};

const TransitionToAdult: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);
  const { modalLoading, setModalLoading } = useContext(ModalLoadingContext);

  const close = () => {
    onClose();
  };

  const {
    studyParticipant,
    onRefetch,
  } = payload;

  const [transitionToAdultMutation] = useMutation<MutationTransitionToAdultArgs>(
    TRANSITION_TO_ADULT,
  );

  const transitionToAdult = async (changeReason: string): Promise<MutationResultType> => {
    setModalLoading(true);
    const result = await transitionToAdultMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
        changeReason,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    setModalLoading(false);
    return result;
  };

  const transitionAndClose = async () => {
    const result = await transitionToAdult(changeReasonValue);
    if (result.errors) {
      setErrors(result.errors);
    } else {
      onClose();
    }
  };

  if (modalLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ width: '350px', height: '325px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Transition Participant to Adult
            </Styled.HeaderText>
            <Styled.BodyText>
              Would you like to Transition participant with PID:
              <strong>
                {` "${studyParticipant.participantId || 'None'}" `}
              </strong>
              in Study with ID:
              <strong>
                {` ${studyParticipant.study.uid} `}
              </strong>
              to an Adult Account?
            </Styled.BodyText>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="change reason"
              label="Change Reason"
              id="change reason"
              onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
            />
          </Styled.TextContainer>
          {errors.map((error) => (
            <div key={error.message} style={{ color: 'red' }}>
              {error.message}
            </div>
          ))}
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={close}>
            <Styled.NoButtonText>
              No
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={transitionAndClose} disabled={!changeReasonValue}>
            <Styled.YesButtonText>
              Yes - Transition
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default TransitionToAdult;
