import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  LoadingSpinner, Box, Text,
} from '@bighealth/react-limbix-ui';

import ParticipantTable from './ParticipantTable';

import {
  QueryStudyParticipantsArgs,
  MutationResetAccessAttemptsArgs,
  MutationGenerateCaseReportFormsArgs,
  MutationGenerateCaseReportFormArgs,
  QueryLimbixStudyArgs,
} from '@/apollo/types';
import { MANAGE_PARTICIPANTS_STUDY_QUERY, STUDY_QUERY_PARTICIPANT_DATA } from '@/apollo/queries';
import {
  RESET_ACCESS_ATTEMPTS,
  GENERATE_STUDY_CRFS,
  GENERATE_PARTICIPANT_CRF,
} from '@/apollo/mutations';
import { MutationResultType, UseParamsType } from '@/types';
import { useModal, usePermissions } from '@/hooks/redux';
import { useLazyQuery, useMutation, useQuery } from '@/hooks/apollo';
import { isPermissionDeniedError } from '@/utils/errorUtils';
import usePagination, { ColumnToOrderByMap, getDefaultPaginationState } from '@/hooks/usePagination';
import StudyActionLinks from '@/components/StudyActionLinks';

const DEFAULT_PAGINATION_STATE = getDefaultPaginationState(
  { columnId: 'pid', direction: 'desc' },
);

const columnToOrderByMap: ColumnToOrderByMap = {
  pid: 'participant_id',
  accountType: 'user__account_type',
  email: 'user__email',
  emailConfirmed: 'user__email_verified',
  language: 'user__language',
  studyArm: 'study_arm__name',
};

const ManageParticipants: React.FC = () => {
  const { showModal } = useModal();
  const [{ studyPermissions }] = usePermissions();
  const { studyUid } = useParams<UseParamsType>();
  const [generateCRFsLoading, setGenerateCRFsLoading] = useState(false);

  const [paginationState, paginationQueryVariables, updatePaginationState] = usePagination(
    DEFAULT_PAGINATION_STATE,
    columnToOrderByMap,
  );
  const variables = {
    studyUid,
    ...paginationQueryVariables,
  };

  const [
    participantsQuery,
    {
      data,
      error,
      loading,
      refetch,
    },
  ] = useLazyQuery<QueryStudyParticipantsArgs>(STUDY_QUERY_PARTICIPANT_DATA, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const studyDataQuery = useQuery<QueryLimbixStudyArgs>(MANAGE_PARTICIPANTS_STUDY_QUERY, {
    variables: {
      studyUid,
    },
  });

  useDeepCompareEffect(() => {
    participantsQuery({ variables });
  }, [paginationState]);

  const [resetAccessAttemptsMutation] = useMutation<MutationResetAccessAttemptsArgs>(
    RESET_ACCESS_ATTEMPTS,
  );

  const resetAccessAttempts = async (userUid: string): Promise<MutationResultType> => {
    const result = await resetAccessAttemptsMutation({
      variables: {
        userUid,
        studyUid,
      },
    });
    await refetch();
    return result;
  };

  const [generateCRFMutation] = useMutation<MutationGenerateCaseReportFormArgs>(
    GENERATE_PARTICIPANT_CRF,
  );
  const generateCRF = (userUid: string): Promise<MutationResultType> => (
    generateCRFMutation({
      variables: {
        userUid,
        studyUid,
      },
    })
  );

  const [generateCRFsMutation] = useMutation<MutationGenerateCaseReportFormsArgs>(
    GENERATE_STUDY_CRFS,
  );

  const permissions = studyPermissions[studyUid];

  if (isPermissionDeniedError(error)
    || error?.message === 'LimbixStudy matching query does not exist.') {
    return <Redirect to="/research" />;
  }

  const onPressAddNewParticipant = () => showModal(
    'REGISTER_NEW_USER',
    {
      studyUid,
      onRefetch: refetch,
    },
  );
  const onPressAddExistingParticipant = () => showModal(
    'REGISTER_EXISTING_USER',
    {
      studyUid,
      onRefetch: refetch,
    },
  );
  const study = studyDataQuery.data?.limbixStudy;
  const studyParticipants = data?.studyParticipants || [];
  const studyParticipantsTotal = studyParticipants.length > 0 ? studyParticipants[0].total : 0;
  const canGenerateCRFs = !!study?.caseReportFormTemplate && !permissions.isBlinded;

  const onPressGenerateCRFs = async () => {
    setGenerateCRFsLoading(true);
    const result = await generateCRFsMutation({ variables: { studyUid } });
    if (result.errors) {
      showModal(
        'ERROR_POPUP',
        {
          header: 'Error while generating CRFs:',
          errors: result.errors.map((mutationError) => mutationError.message),
        },
      );
    } else {
      const crfURL = result?.data?.generateCaseReportForms?.url;
      if (crfURL) {
        window.open(crfURL, '_blank');
      }
    }

    setGenerateCRFsLoading(false);
  };

  const generateCRFsButton = generateCRFsLoading
    ? <LoadingSpinner /> : (
      <Button
        onClick={onPressGenerateCRFs}
        style={{ marginBottom: 16 }}
        variant="contained"
        color="primary"
        disabled={generateCRFsLoading}
      >
        Download Case Report Forms
      </Button>
    );

  const addParticipantsButtons = (
    <>
      <Button
        onClick={onPressAddNewParticipant}
        style={{ marginBottom: 16, marginRight: 16 }}
        variant="contained"
        color="primary"
      >
        Add new participant
      </Button>
      <Button
        onClick={onPressAddExistingParticipant}
        style={{ marginBottom: 16, marginRight: 16 }}
        variant="contained"
        color="primary"
      >
        Add existing participant
      </Button>
    </>
  );

  const relatedStudyActionLinks = (
    <span style={{ textAlign: 'right', display: 'table-cell' }}>
      <Text as="h2">
        View
      </Text>
      <StudyActionLinks study={study} />
    </span>
  );

  return (
    <Box width="100%">
      <span style={{ display: 'table', width: '100%' }}>
        <Text as="h1">
          <span style={{ display: 'table-cell', textAlign: 'left' }}>
            {study?.name}
          </span>
        </Text>
        {relatedStudyActionLinks}
      </span>
      {permissions.canModifyParticipants && addParticipantsButtons}
      {permissions.canViewStudy && canGenerateCRFs && generateCRFsButton}
      <ParticipantTable
        studyUid={studyUid}
        studyParticipants={studyParticipants}
        studyPermissions={permissions}
        resetAccessAttempts={resetAccessAttempts}
        generateCRF={canGenerateCRFs ? generateCRF : null}
        totalCount={studyParticipantsTotal}
        loading={loading}
        paginationState={paginationState}
        updatePaginationState={updatePaginationState}
        onRefetch={refetch}
      />
    </Box>
  );
};

export default ManageParticipants;
