import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
} from '@material-ui/core';
import {
  LoadingSpinner,
} from '@bighealth/react-limbix-ui';

import NumericFieldInput from './NumericFieldInput';
import EnumSelection from './EnumSelection';
import BooleanFieldSwitch from './BooleanFieldSwitch';

import {
  AssessmentType,
  AssignmentScheduleAccountType,
  AssignmentScheduleInputType,
  AssignmentScheduleScheduleOnStudyState,
  AssignmentTypeOfClient,
  AssignmentScheduleTypeOfNotificationForAssignment,
} from '@/apollo/types';
import { UnknownChangeEvent, HTMLInputChangeEvent } from '@/types';

type AssignmentScheduleFormProps = {
  initialAssignmentSchedule: AssignmentScheduleInputType,
  assessments: Array<AssessmentType>,
  onSave: (assignmentSchedule: AssignmentScheduleInputType, changeReason: string) =>
    Promise<void>,
  onClose?: () => void,
}

const AssignmentScheduleForm: React.FC<AssignmentScheduleFormProps> = ({
  initialAssignmentSchedule,
  assessments,
  onSave,
  onClose,
}) => {
  const [updatedAssignmentSchedule, setUpdatedAssignmentSchedule] = useState(initialAssignmentSchedule);
  const [changeReasonText, setChangeReasonText] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const handleAssessmentSelect = (event: UnknownChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      assessmentUid: event.target.value as string,
    });
  };

  const handleAccountTypeSelect = (event: UnknownChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      accountType: event.target.value as string,
    });
  };

  const handleClientTypeSelect = (event: UnknownChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      typeOfClientForAssignment: event.target.value as string,
    });
  };

  const handleScheduleTypeSelect = (event: UnknownChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      scheduleOnStudyState: event.target.value as string,
    });
  };

  const handleNotificationTypeSelect = (event: UnknownChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      typeOfNotificationForAssignment: event.target.value as string,
    });
  };

  const handleCanSkipQuestionsToggle = (_: HTMLInputChangeEvent, checked: boolean) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      canSkipQuestions: checked,
    });
  };

  const handleCanEditAfterCompletionToggle = (_: HTMLInputChangeEvent, checked: boolean) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      canEditAfterCompletion: checked,
    });
  };

  const handleSendPushNotificationsToggle = (_: HTMLInputChangeEvent, checked: boolean) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      sendPushNotifications: checked,
    });
  };

  const handleSendEmailNotificationsToggle = (_: HTMLInputChangeEvent, checked: boolean) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      sendEmailNotifications: checked,
    });
  };

  const handleSendSmsNotificationsToggle = (_: HTMLInputChangeEvent, checked: boolean) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      sendSmsNotifications: checked,
    });
  };

  const handleVisibleDurationDaysChange = (event: HTMLInputChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      visibleDurationDays: Number.parseInt(event.target.value, 10),
    });
  };

  const handleRepeatFrequencyDaysChange = (event: HTMLInputChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      repeatFrequencyDays: Number.parseInt(event.target.value, 10),
    });
  };

  const handleStartTimeOffsetDaysChange = (event: HTMLInputChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      startTimeOffsetDays: Number.parseInt(event.target.value, 10),
    });
  };

  const handleOrdinalChange = (event: HTMLInputChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      ordinal: Number.parseInt(event.target.value, 10),
    });
  };

  const handleCountChange = (event: HTMLInputChangeEvent) => {
    setUpdatedAssignmentSchedule({
      ...updatedAssignmentSchedule,
      count: Number.parseInt(event.target.value, 10),
    });
  };

  const handleSaveAssignmentSchedule = async () => {
    setIsSaving(true);
    await onSave(updatedAssignmentSchedule, changeReasonText);
    setIsSaving(false);
    onClose();
  };

  return (
    <Grid container spacing={2} style={{ margin: 0 }}>
      <Grid item xs={12} container spacing={2} style={{ margin: 0, padding: 0 }}>
        <Grid item style={{ width: '100%' }}>
          <EnumSelection
            attributeLabel="select-assessment"
            label="Assessment"
            value={updatedAssignmentSchedule.assessmentUid}
            options={assessments.map((assessment) => ({ label: assessment.nameInternal, value: assessment.uid }))}
            onSelect={handleAssessmentSelect}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container spacing={2} style={{ margin: 0, padding: 0 }}>
        <Grid item style={{ width: '100%' }}>
          <EnumSelection
            attributeLabel="select-account-type"
            label="Account Type"
            value={updatedAssignmentSchedule.accountType}
            options={Object.values(AssignmentScheduleAccountType).map((type) => ({ label: type, value: type }))}
            onSelect={handleAccountTypeSelect}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <EnumSelection
            attributeLabel="select-client-type"
            label="Client Type"
            value={updatedAssignmentSchedule.typeOfClientForAssignment}
            options={Object.values(AssignmentTypeOfClient).map((type) => ({ label: type, value: type }))}
            onSelect={handleClientTypeSelect}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container spacing={2} style={{ margin: 0, padding: 0 }}>
        <Grid item style={{ width: '100%' }}>
          <EnumSelection
            attributeLabel="select-schedule-on-study-state"
            label="Schedule on Study State"
            value={updatedAssignmentSchedule.scheduleOnStudyState}
            options={Object.values(AssignmentScheduleScheduleOnStudyState).map(
              (type) => ({ label: type, value: type }),
            )}
            onSelect={handleScheduleTypeSelect}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <EnumSelection
            attributeLabel="select-notification-type"
            label="Notification Type"
            value={updatedAssignmentSchedule.typeOfNotificationForAssignment}
            options={Object.values(AssignmentScheduleTypeOfNotificationForAssignment).map(
              (type) => ({ label: type, value: type }),
            )}
            onSelect={handleNotificationTypeSelect}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={6} spacing={0}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item style={{ width: '100%' }}>
            <BooleanFieldSwitch
              label="Can Skip Questions?"
              value={updatedAssignmentSchedule.canSkipQuestions}
              onFlipSwitch={handleCanSkipQuestionsToggle}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <BooleanFieldSwitch
              label="Can Edit After Completion?"
              value={updatedAssignmentSchedule.canEditAfterCompletion}
              onFlipSwitch={handleCanEditAfterCompletionToggle}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <BooleanFieldSwitch
              label="Send Push Notifications? "
              value={updatedAssignmentSchedule.sendPushNotifications}
              onFlipSwitch={handleSendPushNotificationsToggle}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <BooleanFieldSwitch
              label="Send Email Notifications?"
              value={updatedAssignmentSchedule.sendEmailNotifications}
              onFlipSwitch={handleSendEmailNotificationsToggle}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <BooleanFieldSwitch
              label="Send SMS Notifications? "
              value={updatedAssignmentSchedule.sendSmsNotifications}
              onFlipSwitch={handleSendSmsNotificationsToggle}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={6} spacing={0}>
        <Grid
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item style={{ width: '100%' }}>
            <NumericFieldInput
              label="Time Visible (days): "
              value={updatedAssignmentSchedule.visibleDurationDays}
              onChange={handleVisibleDurationDaysChange}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <NumericFieldInput
              label="Repeat Frequency (days): "
              value={updatedAssignmentSchedule.repeatFrequencyDays}
              onChange={handleRepeatFrequencyDaysChange}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <NumericFieldInput
              label="Start Time Offset (days): "
              value={updatedAssignmentSchedule.startTimeOffsetDays}
              onChange={handleStartTimeOffsetDaysChange}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <NumericFieldInput
              label="Ordinal"
              value={updatedAssignmentSchedule.ordinal}
              onChange={handleOrdinalChange}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <NumericFieldInput
              label="Number of Assignments: "
              value={updatedAssignmentSchedule.count}
              onChange={handleCountChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="change-reason-notes"
          variant="outlined"
          label="Change Reason"
          multiline
          required
          maxRows={5}
          minRows={5}
          value={changeReasonText}
          fullWidth
          onChange={(event: HTMLInputChangeEvent) => { setChangeReasonText(event.target.value); }}
        />
      </Grid>
      <Grid item xs={12} container spacing={0}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          { isSaving
            ? <LoadingSpinner />
            : (
              <>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveAssignmentSchedule}
                    disabled={changeReasonText.length === 0}
                  >
                    Save Assignment
                  </Button>
                </Grid>
              </>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssignmentScheduleForm;
