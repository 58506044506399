import React, { useState, useContext } from 'react';
import { TextField } from '@material-ui/core';
import { LoadingSpinner } from '@bighealth/react-limbix-ui';
import { GraphQLError } from 'graphql';

import Styled from '../Modal.styles';
import { ModalLoadingContext } from '../Modal';

import {
  MutationActivateParticipantArgs,
  MutationCompleteParticipantArgs,
  MutationDropParticipantArgs,
  MutationWithdrawParticipantArgs,
  MutationMarkParticipantAsLostArgs,
  MutationMarkParticipantAsScreenFailArgs,
} from '@/apollo/types';
import {
  COMPLETE_PARTICIPANT,
  DROP_PARTICIPANT,
  WITHDRAW_PARTICIPANT,
  MARK_PARTICIPANT_AS_LOST,
  ACTIVATE_PARTICIPANT,
  MARK_PARTICIPANT_SCREEN_FAIL,
} from '@/apollo/mutations';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';

type Props = {
  payload: ModalPayloadType['STUDY_ACTIONS'];
  onClose: () => void;
};
const StudyActions: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const { modalLoading, setModalLoading } = useContext(ModalLoadingContext);

  const { type, studyParticipant, onRefetch } = payload;

  const [activateParticipantMutation] = useMutation<MutationActivateParticipantArgs>(
    ACTIVATE_PARTICIPANT,
  );
  const activateParticipant: () => Promise<MutationResultType> = async () => (
    activateParticipantMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
      },
    })
  );

  const [completeParticipantMutation] = useMutation<MutationCompleteParticipantArgs>(
    COMPLETE_PARTICIPANT,
  );
  const completeParticipant: () => Promise<MutationResultType> = async () => (
    completeParticipantMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
      },
    })
  );

  const [dropParticipantMutation] = useMutation<MutationDropParticipantArgs>(
    DROP_PARTICIPANT,
  );
  const dropParticipant: (changeReason: string) => Promise<MutationResultType> = async (changeReason: string) => (
    dropParticipantMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
        changeReason,
      },
    })
  );

  const [withdrawParticipantMutation] = useMutation<MutationWithdrawParticipantArgs>(
    WITHDRAW_PARTICIPANT,
  );
  const withdrawParticipant: (changeReason: string) => Promise<MutationResultType> = async (changeReason: string) => (
    withdrawParticipantMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
        changeReason,
      },
    })
  );

  const [markParticipantAsLostMutation] = useMutation<MutationMarkParticipantAsLostArgs>(
    MARK_PARTICIPANT_AS_LOST,
  );
  const markParticipantAsLost: (changeReason: string) => Promise<MutationResultType> = async (changeReason: string) => (
    markParticipantAsLostMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
        changeReason,
      },
    })
  );

  const [markParticipantScreenFailMutation] = useMutation<MutationMarkParticipantAsScreenFailArgs>(
    MARK_PARTICIPANT_SCREEN_FAIL,
  );
  const markParticipantScreenFail: (
    changeReason: string,
  ) => Promise<MutationResultType> = async (changeReason: string) => (
    markParticipantScreenFailMutation({
      variables: {
        userUid: studyParticipant.user.uid,
        studyUid: studyParticipant.study.uid,
        changeReason,
      },
    })
  );

  const hasChangeReason = new Set(['lost to follow-up', 'drop', 'withdraw', 'screen fail']);

  const studyActionAndClose = async (mutationType: string) => {
    setModalLoading(true);
    let mutation: MutationResultType;
    switch (mutationType) {
    case 'activate':
      mutation = await activateParticipant();
      break;
    case 'complete':
      mutation = await completeParticipant();
      break;
    case 'lost to follow-up':
      mutation = await markParticipantAsLost(changeReasonValue);
      break;
    case 'drop':
      mutation = await dropParticipant(changeReasonValue);
      break;
    case 'withdraw':
      mutation = await withdrawParticipant(changeReasonValue);
      break;
    case 'screen fail':
      mutation = await markParticipantScreenFail(changeReasonValue);
      break;
    default:
      mutation = null;
    }
    const { errors: resultErrors, data } = mutation;
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      let success = null;
      switch (type) {
      case 'activate':
        success = data.activateParticipant.success;
        break;
      case 'complete':
        success = data.completeParticipant.success;
        break;
      case 'lost to follow-up':
        success = data.markParticipantAsLost.success;
        break;
      case 'drop':
        success = data.dropParticipant.success;
        break;
      case 'withdraw':
        success = data.withdrawParticipant.success;
        break;
      case 'screen fail':
        success = data.markParticipantAsScreenFail.success;
        break;
      default:
        success = false;
      }
      if (success) {
        if (onRefetch) {
          await onRefetch();
        }
        onClose();
      } else {
        setErrors((prev) => ([...prev, new GraphQLError('Unknown GraphQL Failure')]));
      }
    }
    setModalLoading(false);
  };

  return (
    <div style={{ width: '415px', height: errors.length === 0 ? '325px' : '375px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Study Action on Participant
              <br />
              {type.toUpperCase()}
            </Styled.HeaderText>
            <Styled.BodyText>
              Are you sure that you want to complete this study action on this participant?
              <br />
              Action:
              <strong>
                {` '${type}' `}
              </strong>
              <br />
              PID:
              <strong>
                {` '${studyParticipant.participantId || 'None'}' `}
              </strong>
            </Styled.BodyText>
            {errors.map((error) => (
              <div key={error.message} style={{ color: 'red' }}>
                {error.message}
              </div>
            ))}
            {hasChangeReason.has(type) && (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="change reason"
                label="Change Reason"
                id="change reason"
                onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
              />
            )}
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          {modalLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Styled.NoButton onClick={onClose}>
                <Styled.NoButtonText>
                  No
                </Styled.NoButtonText>
              </Styled.NoButton>
              <Styled.YesButton
                onClick={() => studyActionAndClose(type)}
                disabled={hasChangeReason.has(type) && !changeReasonValue}
              >
                <Styled.YesButtonText>
                  Yes -
                  {` ${type}`}
                </Styled.YesButtonText>
              </Styled.YesButton>
            </>
          )}
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default StudyActions;
