import React, { useState, useReducer, useContext } from 'react';
import { TextField, Button } from '@material-ui/core';
import { Link, LoadingSpinner, StaticDatePicker } from '@bighealth/react-limbix-ui';

import Styled from '../Modal.styles';

import {
  LocationType,
  MutationEditPatientInfoArgs,
} from '@/apollo/types';
import { MutationResultType, GraphQLErrorsType } from '@/types';
import { EDIT_PATIENT_INFO } from '@/apollo/mutations';
import { formatDate } from '@/utils/dateUtils';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';
import { ModalLoadingContext } from '@/components/Modal/Modal';

type Props = {
  payload: ModalPayloadType['PATIENT_CONTACT_INFO'];
  onClose: () => void;
};
const initialState = {
  isEditing: false,
  fieldEdited: null as string,
  dateOfBirth: null as Date,
  firstName: null as string,
  lastName: null as string,
  email: null as string,
  phone: null as string,
  emergencyContactEmail: null as string,
  emergencyContactName: null as string,
  emergencyContactPhone: null as string,
  location: null as LocationType,
};

type StateType = typeof initialState;

type FormStateType = Omit<StateType, 'isEditing' | 'fieldEdited'>;

type FormField = keyof FormStateType;

type FormValue = string | Date | LocationType;

type ActionType = {
  type: 'EDIT_FIELD',
  fieldEdited?: keyof FormStateType,
  value?: FormStateType[FormField],
} | {
  type: 'DONE_EDITING',
} | {
  type: 'ABANDON_EDIT',
  resetValue? : FormStateType[FormField],
};

function reducer(state: StateType, action: ActionType) {
  switch (action.type) {
  case 'EDIT_FIELD':
    return {
      ...state,
      isEditing: true,
      fieldEdited: action.fieldEdited ?? state.fieldEdited,
      [state.fieldEdited]: action.value ?? state[action.fieldEdited],
    };
  case 'DONE_EDITING':
    return {
      ...state,
      isEditing: false,
      fieldEdited: null,
    };
  case 'ABANDON_EDIT':
    return {
      ...state,
      isEditing: false,
      [state.fieldEdited]: action.resetValue,
      fieldEdited: null,
    };
  default:
    throw new Error('Unexpected State');
  }
}

const PatientContactInfo: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { careteam, onRefetch } = payload;

  const initialValues = {
    firstName: careteam.patient.user.firstName,
    lastName: careteam.patient.user.lastName,
    dateOfBirth: careteam.patient.user.dateOfBirth as Date,
    email: careteam.patient.user.email,
    phone: careteam.patient.user.phone,
    emergencyContactEmail: careteam.patient.emergencyContactEmail,
    emergencyContactName: careteam.patient.emergencyContactName,
    emergencyContactPhone: careteam.patient.emergencyContactPhone,
    location: careteam.patient.location,
  } as { [key: string]: string | Date | LocationType } & FormStateType;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...initialValues,
  });
  const [changeReasonValue, setChangeReasonValue] = useState<string>();
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);
  const { modalLoading, setModalLoading } = useContext(ModalLoadingContext);
  const hasEmergencyContactInfo = initialValues.emergencyContactEmail
    || initialValues.emergencyContactName
    || initialValues.emergencyContactPhone;

  const [editParticipantInfoMutation] = useMutation<MutationEditPatientInfoArgs>(
    EDIT_PATIENT_INFO,
  );
  const editParticipantInfo = async (
    value: FormValue, changeReason: string,
  ): Promise<MutationResultType> => {
    setModalLoading(true);
    const result = await editParticipantInfoMutation({
      variables: {
        patientUid: careteam.patient.uid,
        [`${state.fieldEdited}`]: value,
        changeReason,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    setModalLoading(false);
    return result;
  };

  const updateInfo = async () => {
    const { errors: resultErrors } = await editParticipantInfo(
      state[state.fieldEdited as FormField],
      changeReasonValue,
    );
    if (resultErrors) {
      setErrors(resultErrors);
    } else {
      onClose();
    }
  };

  const renderErrors = () => (
    <>
      {errors.map((error) => (
        <div key={error.message} style={{ color: 'red' }}>
          {error.message}
        </div>
      ))}
    </>
  );

  const renderChangeReason = () => (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      name="change reason"
      label="Change Reason"
      id="change reason"
      onChange={(event) => setChangeReasonValue(event.target.value?.trim())}
    />
  );

  const startEdit = (fieldName: keyof FormStateType) => {
    dispatch({ type: 'ABANDON_EDIT', resetValue: initialValues[state.fieldEdited] });
    setChangeReasonValue(null);
    dispatch({ type: 'EDIT_FIELD', fieldEdited: fieldName });
  };

  const renderFirstName = () => (
    <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
      <Styled.BodyText style={{ marginRight: '15px' }}>
        <strong>First Name:</strong>
        {` ${state.firstName} `}
        <Link.ButtonLink onClick={() => startEdit('firstName')}>
          edit
        </Link.ButtonLink>
      </Styled.BodyText>
      {state.isEditing && state.fieldEdited === 'firstName' && (
        <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="firstName"
            label="First Name"
            value={state.firstName}
            id="firstName"
            onChange={(event) => dispatch({ type: 'EDIT_FIELD', value: event.target.value })}
          />
          {renderChangeReason()}
        </div>
      )}
    </div>
  );

  const renderLastName = () => (
    <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
      <Styled.BodyText style={{ marginRight: '15px' }}>
        <strong>Last Name:</strong>
        {` ${state.lastName} `}
        <Link.ButtonLink onClick={() => startEdit('lastName')}>
          edit
        </Link.ButtonLink>
      </Styled.BodyText>
      {state.isEditing && state.fieldEdited === 'lastName' && (
        <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="lastName"
            label="Last Name"
            value={state.lastName}
            id="lastName"
            onChange={(event) => dispatch({ type: 'EDIT_FIELD', value: event.target.value })}
          />
          {renderChangeReason()}
        </div>
      )}
    </div>
  );

  const renderDOB = () => (
    <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
      <Styled.BodyText style={{ marginRight: '15px' }}>
        <strong>Date of Birth:</strong>
        {` ${formatDate(state.dateOfBirth)} `}
        <Link.ButtonLink onClick={() => startEdit('dateOfBirth')}>
          edit
        </Link.ButtonLink>
      </Styled.BodyText>
      {state.isEditing && state.fieldEdited === 'dateOfBirth' && (
        <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
          <StaticDatePicker
            selectedDate={state.dateOfBirth}
            onChange={(date: Date) => dispatch({ type: 'EDIT_FIELD', value: date })}
          />
          {renderChangeReason()}
        </div>
      )}
    </div>
  );

  const renderEmail = () => (
    <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
      <Styled.BodyText style={{ marginRight: '15px' }}>
        <strong>Email:</strong>
        {` ${state.email} `}
        <Link.ButtonLink onClick={() => startEdit('email')}>
          edit
        </Link.ButtonLink>
      </Styled.BodyText>
      {state.isEditing && state.fieldEdited === 'email' && (
        <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="email"
            label="Email"
            value={state.email}
            id="email"
            onChange={(event) => dispatch({ type: 'EDIT_FIELD', value: event.target.value })}
          />
          {renderChangeReason()}
        </div>
      )}
    </div>
  );

  const renderPhone = () => (
    <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
      <Styled.BodyText style={{ marginRight: '15px' }}>
        <strong>Phone:</strong>
        {` ${state.phone} `}
      </Styled.BodyText>
    </div>
  );

  const renderAddress = () => (
    <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
      <Styled.BodyText style={{ marginRight: '15px' }}>
        <strong>Location:</strong>
        <br />
        {` ${state.location.line.join('\n')} `}
        <br />
        {` ${state.location.city}, ${state.location.state} ${state.location.postalCode} `}
      </Styled.BodyText>
    </div>
  );

  const renderEmergencyContactEmail = () => (
    <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
      <Styled.BodyText style={{ marginRight: '15px' }}>
        <strong>Emergency Contact Email:</strong>
        {` ${state.emergencyContactEmail} `}
      </Styled.BodyText>
    </div>
  );

  const renderEmergencyContactName = () => (
    <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
      <Styled.BodyText style={{ marginRight: '15px' }}>
        <strong>Emergency Contact Name:</strong>
        {` ${state.emergencyContactName} `}
      </Styled.BodyText>
    </div>
  );

  const renderEmergencyContactPhone = () => (
    <div style={{ fontSize: '.9em', alignItems: 'flex-start' }}>
      <Styled.BodyText style={{ marginRight: '15px' }}>
        <strong>Emergency Contact Phone Number:</strong>
        {` ${state.emergencyContactPhone} `}
      </Styled.BodyText>
    </div>
  );

  if (modalLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div
      style={{
        width: '500px',
        height: state.isEditing && state.fieldEdited === 'dateOfBirth' ? '800px' : '500px',
      }}
    >
      <Styled.DialogContent>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <Styled.HeaderText style={{ marginBottom: 5 }}>
              View Patient Info
            </Styled.HeaderText>
            <Styled.BodyText>
              <strong>Patient:</strong>
              {` ${careteam.patient.uid.substring(0, 6)}`}
            </Styled.BodyText>
            {renderErrors()}
            {renderFirstName()}
            {renderLastName()}
            {renderEmail()}
            {renderPhone()}
            {renderDOB()}
            {initialValues.location && renderAddress()}
            {hasEmergencyContactInfo && (
              <>
                <Styled.HeaderText style={{ marginTop: 10, marginBottom: 10 }}>
                  Emergency Contact Info
                </Styled.HeaderText>
                {renderEmergencyContactName()}
                {renderEmergencyContactEmail()}
                {renderEmergencyContactPhone()}
              </>
            )}
          </div>
          <Styled.ButtonContainer>
            {!state.isEditing ? (
              <Button
                variant="contained"
                onClick={onClose}
                style={{ marginRight: 15 }}
              >
                Close
              </Button>
            ) : (
              <>
                <Styled.NoButton
                  onClick={() => dispatch({ type: 'ABANDON_EDIT', resetValue: initialValues[state.fieldEdited] })}
                  style={{ width: '150px' }}
                >
                  <Styled.NoButtonText>
                    Abandon Changes
                  </Styled.NoButtonText>
                </Styled.NoButton>
                <Styled.YesButton
                  onClick={() => updateInfo()}
                  disabled={!changeReasonValue}
                >
                  <Styled.YesButtonText>
                    Save
                  </Styled.YesButtonText>
                </Styled.YesButton>
              </>
            )}
          </Styled.ButtonContainer>
        </div>
      </Styled.DialogContent>
    </div>
  );
};

export default PatientContactInfo;
