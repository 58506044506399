import React, { useState } from 'react';
import { GraphQLError } from 'graphql';
import { TextField } from '@material-ui/core';

import {
  DataGridColumn as Column,
  DataGridRow as Row,
  DataGridCell as Cell,
  AnswerValue,
} from '@bighealth/react-limbix-ui';

import Styled from '../Modal.styles';

import { GraphQLErrorsType } from '@/types';
import { formatDate } from '@/utils/dateUtils';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['DATA_GRID_CONFIRMATION'];
  onClose: () => void;
};

const DataGridConfirmation: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload: {
      columns,
      editedRows,
      mutations,
      onFinishMutations,
    },
  } = props;
  const [errors, setErrors] = useState<GraphQLErrorsType>([]);
  const [changeReasons, setChangeReasons] = useState<string[]>(new Array(editedRows.length).fill(''));

  const saveButtonDisabled = changeReasons.length === 0 || changeReasons.some((changeReason) => !changeReason);

  const format = (answer: AnswerValue) => {
    if (answer instanceof Date) {
      return formatDate(answer);
    }
    if (Array.isArray(answer)) {
      return answer.length === 0 ? '' : JSON.stringify(answer);
    }
    return `${answer}`;
  };

  const onEnterChangeReason = (rowIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value?.trim();
    setChangeReasons((prevChangeReasons) => {
      prevChangeReasons.splice(rowIndex, 1, trimmedValue);
      return [...prevChangeReasons];
    });
  };

  const renderStaticData = (staticData: Array<[string, Cell]>, rowId: string) => (
    <div>
      {staticData.map(([key, cell]) => (
        <div key={rowId + key}>
          <strong>
            {key}
          </strong>
          {`: ${cell?.value || 'None'}`}
        </div>
      ))}
    </div>
  );

  const renderEditedData = (cols: Column[], changedData: Array<[string, Cell]>, rowId: string) => (
    <ul style={{ paddingLeft: '15px' }}>
      {changedData.map(([key, cell]) => (
        <li key={rowId + key}>
          {`${cols.find((column) => column.field === key).headerName}: `}
          <ul style={{ paddingLeft: '15px' }}>
            <li>
              <strong>
                Original
              </strong>
              {`: ${format(cell.originalValue)}`}
            </li>
            <li>
              <strong>
                New
              </strong>
              {`: ${format(cell.value)}`}
            </li>
          </ul>
        </li>
      ))}
    </ul>
  );

  const renderChangeReasonInput = (changedData: Array<[string, Cell]>, rowIndex: number) => {
    const allOriginalValuesBlank = changedData.every(([, cell]) => cell.originalValue === '');
    if (allOriginalValuesBlank) {
      if (changeReasons[rowIndex] === '') {
        setChangeReasons((prevChangeReasons) => {
          prevChangeReasons.splice(rowIndex, 1, 'Editing answer from blank state');
          return [...prevChangeReasons];
        });
      }
      return (<></>);
    }
    return (
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name={`change reason for ${rowIndex}`}
        label="Change Reason"
        id={`change reason for ${rowIndex}`}
        onChange={onEnterChangeReason(rowIndex)}
      />
    );
  };

  const renderEditedRow = (cols: Column[], row: Row, rowIndex: number) => {
    const staticData = [] as Array<[string, Cell]>;
    const changedData = [] as Array<[string, Cell]>;
    Object.entries(row).forEach(([key, cell]) => (
      cols.find((column) => column.field === key)?.static || key === 'id'
        ? staticData.push([key, cell])
        : changedData.push([key, cell])
    ));
    const rowId = row.id.value as string;
    return (
      <li key={rowId}>
        {renderStaticData(staticData, rowId)}
        {renderEditedData(cols, changedData, rowId)}
        {renderChangeReasonInput(changedData, rowIndex)}
      </li>
    );
  };

  const onSave = async () => {
    const mutationErrors: GraphQLError[] = [];
    const results = await Promise.all(
      mutations.map((mutation, index) => (mutation(changeReasons[index]))),
    );
    results.forEach((result) => {
      if (result.errors && result.errors?.length > 0) {
        mutationErrors.push(...result.errors);
      }
    });
    if (mutationErrors.length > 0) {
      setErrors([...mutationErrors]);
    } else {
      onFinishMutations();
      onClose();
    }
  };

  return (
    <div style={{ width: '450px', height: '600px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'block', height: '100%' }}>
          <Styled.TextContainer>
            <Styled.HeaderText>
              Please Confirm Your Changes
            </Styled.HeaderText>
            <div style={{ height: '425px', overflowY: 'scroll' }}>
              <strong>Scroll to see more...</strong>
              <ul style={{ paddingLeft: '15px' }}>
                {editedRows.map((row, rowIndex) => (
                  <div key={row.id.value as string}>
                    {renderEditedRow(columns, row, rowIndex)}
                    <hr />
                  </div>
                ))}
              </ul>
            </div>
          </Styled.TextContainer>
          {errors.map((error, index) => (
            <div key={+index + error.message} style={{ color: 'red' }}>
              {error.message}
            </div>
          ))}
        </div>
        <Styled.ButtonContainer>
          <Styled.YesButton onClick={onSave} disabled={saveButtonDisabled}>
            <Styled.YesButtonText>
              Save
            </Styled.YesButtonText>
          </Styled.YesButton>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              Close
            </Styled.NoButtonText>
          </Styled.NoButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default DataGridConfirmation;
