import React from 'react';
import { LoadingSpinner } from '@bighealth/react-limbix-ui';

import Styled from './FreeFormTexts.styles';

import { QueryParticipantFreeFormTextsArgs } from '@/apollo/types';
import { PARTICIPANT_FREE_FORM_TEXTS } from '@/apollo/queries';
import { formatDate } from '@/utils/dateUtils';
import { useQuery } from '@/hooks/apollo';
import { usePermissions } from '@/hooks/redux';

type Props = {
  studyUid: string;
  userUid: string;
}

const FreeFormTexts: React.FC<Props> = (props: Props) => {
  const { studyUid, userUid } = props;
  const [{ studyPermissions }] = usePermissions();
  const { loading, data } = useQuery<QueryParticipantFreeFormTextsArgs>(PARTICIPANT_FREE_FORM_TEXTS, {
    variables: {
      studyUid,
      userUid,
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }
  const permissions = studyPermissions[studyUid];
  if (permissions.isBlinded) {
    return (
      <h1>
        You are blinded for this study. You cannot review free form text.
      </h1>
    );
  }

  const { participantFreeFormTexts } = data;

  if (!participantFreeFormTexts || participantFreeFormTexts.length === 0) {
    return (
      <h1>
        {'It seems there aren\'t any free form texts for this participant'}
        <br />
        If that seems like a mistake please reach out to Research and Eng
      </h1>
    );
  }

  return (
    <div style={{ width: '100%', paddingTop: '15px' }}>
      <Styled.BoldText>
        Free Form Text
      </Styled.BoldText>
      <Styled.OrderedList>
        {participantFreeFormTexts.map((freeformtext) => (
          <li key={freeformtext.uid} style={{ marginBottom: '10px' }}>
            <div>
              <Styled.BoldText>
                Date:
              </Styled.BoldText>
              <Styled.DetailText>
                {formatDate(freeformtext.createdAt)}
              </Styled.DetailText>
            </div>
            <div>
              <Styled.BoldText>
                Prompt:
              </Styled.BoldText>
              <Styled.DetailText>
                {freeformtext.prompt}
              </Styled.DetailText>
            </div>
            <div>
              <Styled.BoldText>
                Response:
              </Styled.BoldText>
              <Styled.DetailText>
                {freeformtext.value}
              </Styled.DetailText>
            </div>
          </li>
        ))}
      </Styled.OrderedList>
    </div>
  );
};

export default FreeFormTexts;
