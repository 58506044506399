import React, { useContext } from 'react';
import { LoadingSpinner } from '@bighealth/react-limbix-ui';

import Styled from '../Modal.styles';

import { REVOKE_MOBILE_ACCESS } from '@/apollo/mutations';
import { MutationRevokeMobileAccessArgs } from '@/apollo/types';
import ExclamationSVG from '@/assets/ExclamationCircle.svg';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useMutation } from '@/hooks/apollo';
import { ModalLoadingContext } from '@/components/Modal/Modal';

type Props = {
  payload: ModalPayloadType['REVOKE_ACCESS'];
  onClose: () => void;
};
const RevokeAccess: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const { studyParticipant, onRefetch } = payload;

  const { modalLoading, setModalLoading } = useContext(ModalLoadingContext);

  const [revokeMobileAccessMutation] = useMutation<MutationRevokeMobileAccessArgs>(
    REVOKE_MOBILE_ACCESS,
  );
  const revokeMobileAccess = async (userUid: string) => {
    setModalLoading(true);
    const result = await revokeMobileAccessMutation({
      variables: {
        userUid,
        studyUid: studyParticipant.study.uid,
      },
    });
    if (onRefetch) {
      await onRefetch();
    }
    setModalLoading(false);
    return result;
  };

  const revokeAndClose = async () => {
    await revokeMobileAccess(studyParticipant.user.uid);
    onClose();
  };

  if (modalLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ width: '350px', height: '225px' }}>
      <Styled.DialogContent>
        <div style={{ display: 'flex' }}>
          <Styled.WarningSVGContainer>
            <Styled.WarningSVG src={ExclamationSVG} />
          </Styled.WarningSVGContainer>
          <Styled.TextContainer>
            <Styled.HeaderText>
              {'Revoke Participant\'s Mobile Access?'}
            </Styled.HeaderText>
            <Styled.BodyText>
              {'Would you like to Revoke Participant\'s Mobile Access with PID:'}
              <strong>
                {` "${studyParticipant.participantId || 'None'}" `}
              </strong>
              in Study with ID:
              <strong>
                {` ${studyParticipant.study.uid} `}
              </strong>
            </Styled.BodyText>
          </Styled.TextContainer>
        </div>
        <Styled.ButtonContainer>
          <Styled.NoButton onClick={onClose}>
            <Styled.NoButtonText>
              No
            </Styled.NoButtonText>
          </Styled.NoButton>
          <Styled.YesButton onClick={revokeAndClose}>
            <Styled.YesButtonText>
              Yes - Revoke
            </Styled.YesButtonText>
          </Styled.YesButton>
        </Styled.ButtonContainer>
      </Styled.DialogContent>
    </div>
  );
};

export default RevokeAccess;
