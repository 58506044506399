import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Grid, Switch } from '@material-ui/core';
import { LoadingSpinner, Box } from '@bighealth/react-limbix-ui';

import { AssignmentScheduleViewer } from '@/components';
import {
  QueryStudyAssignmentSchedulesArgs,
  MutationUpdateStudyAssignmentScheduleArgs,
  AssignmentScheduleInputType,
  MutationDeleteStudyAssignmentScheduleArgs,
} from '@/apollo/types';
import { UseParamsType } from '@/types';
import { STUDY_ASSIGNMENT_SCHEDULES } from '@/apollo/queries';
import { DELETE_STUDY_ASSIGNMENT_SCHEDULE, UPDATE_STUDY_ASSIGNMENT_SCHEDULE } from '@/apollo/mutations';
import { useQuery, useMutation } from '@/hooks/apollo';
import { useAssignmentScheduleEditorQueries } from '@/hooks/apollo/queries';
import history from '@/utils/history';
import AssignmentScheduleEditor from '@/components/AssignmentScheduleEditor/AssignmentScheduleEditor';
import { getAssignmentScheduleInputsWithLockState } from '@/utils/assessmentUtils';

type Props = {
  studyUid: string,
}
const Viewer: React.FC<Props> = (props: Props) => {
  const { studyUid } = props;
  const { data, loading } = useQuery<QueryStudyAssignmentSchedulesArgs>(
    STUDY_ASSIGNMENT_SCHEDULES,
    {
      variables: {
        studyUid,
      },
    },
  );
  if (loading) {
    return <LoadingSpinner />;
  }
  const assignmentSchedules = data.studyAssignmentSchedules || [];
  return (
    <AssignmentScheduleViewer assignmentSchedules={assignmentSchedules} />
  );
};

const Editor: React.FC<Props> = (props: Props) => {
  const { studyUid } = props;

  const {
    loading,
    assessments,
    refetchAssignmentSchedules,
    assignmentSchedules,
  } = useAssignmentScheduleEditorQueries(studyUid);

  const [
    updateAssignmentScheduleMutation,
  ] = useMutation<MutationUpdateStudyAssignmentScheduleArgs>(
    UPDATE_STUDY_ASSIGNMENT_SCHEDULE,
  );

  const [
    deleteAssignmentScheduleMutation,
  ] = useMutation<MutationDeleteStudyAssignmentScheduleArgs>(
    DELETE_STUDY_ASSIGNMENT_SCHEDULE,
  );

  const updateAssignmentSchedule = async (
    assignmentSchedule: AssignmentScheduleInputType,
    changeReason: string,
  ) => (
    updateAssignmentScheduleMutation(
      {
        variables: {
          studyUid,
          assignmentScheduleData: assignmentSchedule,
          changeReason,
        },
      },
    )
  );

  const deleteAssignmentSchedule = async (
    assignmentScheduleUid: string,
    changeReason: string,
  ) => (
    deleteAssignmentScheduleMutation(
      {
        variables: {
          assignmentScheduleUid,
          changeReason,
        },
      },
    )
  );

  const handleSave = async (assignmentSchedule: AssignmentScheduleInputType, changeReason: string) => {
    await updateAssignmentSchedule(assignmentSchedule, changeReason);
    await refetchAssignmentSchedules();
  };

  const handleDelete = async (assignmentScheduleUid: string, changeReason: string) => {
    const result = await deleteAssignmentSchedule(assignmentScheduleUid, changeReason);
    await refetchAssignmentSchedules();
    return result;
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <AssignmentScheduleEditor
      assignmentSchedules={getAssignmentScheduleInputsWithLockState(assignmentSchedules)}
      assessments={assessments}
      onSave={handleSave}
      onDelete={handleDelete}
    />
  );
};

const editModeSwitch = (isInEditMode: boolean, stateChangeUrl: string) => (
  <Box width="130px">
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>View</Grid>
      <Grid item>
        <Switch
          color="primary"
          checked={isInEditMode}
          value={isInEditMode}
          onChange={() => history.replace(stateChangeUrl)}
        />
      </Grid>
      <Grid item>Edit</Grid>
    </Grid>
  </Box>
);

const AssignmentSchedules: React.FC = () => {
  const { studyUid } = useParams<UseParamsType>();
  const location = useLocation();
  const isInEditMode = location.pathname.includes('edit');
  const stateChangeUrl = isInEditMode ? location.pathname.replace('/edit', '') : `${location.pathname}/edit`;

  return (
    <Box height="100%">
      {editModeSwitch(isInEditMode, stateChangeUrl)}
      <Box height="100%">
        {!isInEditMode
          ? (
            <Viewer studyUid={studyUid} />
          ) : (
            <Editor studyUid={studyUid} />
          )}
      </Box>
    </Box>
  );
};

export default AssignmentSchedules;
