import React from 'react';
import { Link, Box } from '@bighealth/react-limbix-ui';

import {
  MutationResendEmailVerificationArgs,
  MutationResetPasswordArgs,
  CareTeamType,
} from '@/apollo/types';
import { RESEND_VERIFICATION_EMAIL, RESET_PASSWORD } from '@/apollo/mutations';
import { useModal } from '@/hooks/redux';
import { useMutation } from '@/hooks/apollo';

type Props = {
  careteam: CareTeamType;
};
const AdminActions: React.FC<Props> = (props: Props) => {
  const { careteam } = props;
  const { showModal } = useModal();

  const [resendEmailVerificationMutation] = useMutation<MutationResendEmailVerificationArgs>(
    RESEND_VERIFICATION_EMAIL,
  );
  const resendEmailVerification = async (email: string) => (
    resendEmailVerificationMutation({
      variables: {
        email,
      },
    })
  );

  const [sendPasswordResetMutation] = useMutation<MutationResetPasswordArgs>(
    RESET_PASSWORD,
  );
  const sendPasswordReset = async (email: string) => (
    sendPasswordResetMutation({
      variables: {
        email,
      },
    })
  );

  const patientEmailVerified = careteam?.patient?.user?.emailVerified;

  return (
    <Box>
      <Link.ButtonLink
        onClick={
          () => showModal(
            'VIEW_PROVIDERS',
            { careteam },
          )
        }
      >
        view provider info
      </Link.ButtonLink>
      {!patientEmailVerified && (
        <>
          {' | '}
          <Link.ButtonLink
            onClick={
              () => showModal(
                'CONFIRMATION_POPUP',
                {
                  header: 'Would you like to resend account verification?',
                  mutation: () => resendEmailVerification(careteam.patient.user.email),
                  context: { uid: careteam.patient.uid.substring(0, 6) },
                },
              )
            }
          >
            send account verification
          </Link.ButtonLink>
        </>
      )}
      {' | '}
      <Link.ButtonLink
        onClick={
          () => showModal(
            'CONFIRMATION_POPUP',
            {
              header: 'Would you like to reset the patient\'s password?',
              mutation: () => sendPasswordReset(careteam.patient.user.email),
              context: { uid: careteam.patient.uid.substring(0, 6) },
            },
          )
        }
      >
        send password reset
      </Link.ButtonLink>
    </Box>
  );
};

export default AdminActions;
