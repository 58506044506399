import React from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';
import styled from 'styled-components';
import { Memo } from '@bighealth/react-limbix-ui';

const StyledMuiIconButton = styled(MuiIconButton)`
  display: flex;
  padding: 0;
  cursor: pointer;
`;

type IconButtonProps = {
  onClick: () => void;
}
const IconButton: React.FC<IconButtonProps> = (({
  onClick,
  children,
}) => (
  <StyledMuiIconButton onClick={onClick}>
    {children}
  </StyledMuiIconButton>
));

export default Memo(IconButton, () => false);
