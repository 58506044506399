import { useState } from 'react';
import { SortState } from '@bighealth/react-limbix-ui';

export type ColumnToOrderByMap = Record<string, string[] | string>;
export type PaginationState = {
  itemsPerPage: number;
  currentPage: number;
  sortState: SortState;
  searchFilterState: Record<string, string>;
}
export type PaginationQueryVariables = {
 limit: number;
 offset: number;
 orderBy: string[];
 searchFilters: string;
}
export type UpdatePaginationStateType = (paginationStateUpdates: Partial<PaginationState>) => void
type usePaginationReturnType = [
  PaginationState,
  PaginationQueryVariables,
  UpdatePaginationStateType,
];

const DEFAULT_ITEMS_PER_PAGE = 30;

export const getDefaultPaginationState = (
  defaultSortState?: SortState,
  itemsPerPage?: number,
): PaginationState => ({
  sortState: defaultSortState ?? { columnId: '', direction: 'asc' },
  itemsPerPage: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
  currentPage: 0,
  searchFilterState: {},
});

const getOrderByKeysFromSortState = (
  sortState: SortState,
  columnToOrderByMap: ColumnToOrderByMap,
): string[] => {
  if (!(sortState.columnId in columnToOrderByMap)) {
    return [];
  }
  const orderByKeyOrKeys = columnToOrderByMap[sortState.columnId];
  const orderByKeys = Array.isArray(orderByKeyOrKeys) ? orderByKeyOrKeys : [orderByKeyOrKeys];
  if (sortState.direction === 'desc') {
    return orderByKeys.map((key) => (`-${key}`));
  }
  return orderByKeys;
};

const getPaginationQueryVariables = (
  paginationState: PaginationState,
  columnToOrderByMap: ColumnToOrderByMap,
): PaginationQueryVariables => ({
  limit: paginationState.itemsPerPage,
  offset: paginationState.currentPage * paginationState.itemsPerPage,
  orderBy: getOrderByKeysFromSortState(paginationState.sortState, columnToOrderByMap),
  searchFilters: JSON.stringify(paginationState.searchFilterState),
});

/*
This hook is used to encapsulate some of the shared logic for paginated tables.
It manages pagination state and provides the consumer with both pagination state and
pre-formatted query variables for the paginated query (limit, offset, orderBy).
 */
const usePagination = (
  defaultPaginationState: PaginationState,
  columnToOrderByMap: ColumnToOrderByMap,
): usePaginationReturnType => {
  const [paginationState, setPaginationState] = useState(defaultPaginationState);
  const queryVariables = getPaginationQueryVariables(paginationState, columnToOrderByMap);

  const onUpdatePaginationState = (newState: Partial<PaginationState>) => setPaginationState({
    ...paginationState,
    ...newState,
  });

  return [paginationState, queryVariables, onUpdatePaginationState];
};

export default usePagination;
